import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Stack,
} from '@chakra-ui/react';
import CandidateResource from 'api/candidate';
import { infoStyles, labelStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import routes from 'constants/routes';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

const ViewCandidate: React.FC = () => {
  const { id }: any = useParams();
  const api = new CandidateResource();
  const navigate = useNavigate();

  const dataQuery = useQuery<any>(
    [`candidate${id}`, id],
    () => api.get(id).then((res) => res?.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (dataQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (dataQuery.isError) {
    navigate(routes.clients.list);
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            Candidate Detail
          </Heading>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <Grid
            gap="4"
            templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Name</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.name || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Contact Number</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.contact || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Date of Birth</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.dob || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Passport Number</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.passport_number || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Province/State</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.province || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>District</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.district || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Village/City/Town</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.city || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Secondary Contact Name</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.emergency_contact_name || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Secondary Contact Number</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.emergency_contact_number || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>
                  Secondary Contact Relation
                </FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.emergency_contact_relation || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Email</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.email || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Job Description</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.job_description || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Country Willing</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.country_willing || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Previous Experience</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.previous_experience || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>PP Size Photo</FormLabel>
                <Image src={dataQuery?.data?.pp_size_photo_url} />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Full Size Photo</FormLabel>
                <Image src={dataQuery?.data?.full_size_photo_url} />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Passport Front</FormLabel>
                <Image src={dataQuery?.data?.passport_front_url} />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Passport Back</FormLabel>
                <Image src={dataQuery?.data?.passport_back_url} />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>CV</FormLabel>
                <Image src={dataQuery?.data?.cv_url} />
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};

export default ViewCandidate;
