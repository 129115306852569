import { Box } from '@chakra-ui/react';
import FooterPage from 'components/landing/Footer';
import Header from 'components/landing/Header';
import React from 'react';

const TalentHuntPage: React.FC = () => {
  return (
    <>
      <Header />
      <Box p={4}>TalentHuntPage</Box>
      <FooterPage />
    </>
  );
};

export default TalentHuntPage;
