import { Td, Tr } from '@chakra-ui/react';
import routes from 'constants/routes';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  listData: any;
  index: number;
}
const ClientListItem: React.FC<Props> = (props) => {
  const { listData, index } = props;
  return (
    <Tr key={listData.id}>
      <Td>{index}</Td>
      <Td>
        <RouterLink
          to={routes.clients.view.replace(':id', listData?.id.toString())}>
          {listData?.company_name}
        </RouterLink>
      </Td>
      <Td>{listData.office_number}</Td>
      <Td>
        {listData.city} , {listData.state}, {listData.country}
      </Td>
      <Td>{listData.representative_name}</Td>
    </Tr>
  );
};

export default ClientListItem;
