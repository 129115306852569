import {
  Box,
  Container,
  Heading,
  IconButton,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import {
  construction_worker,
  workers,
  worker_working_in_factory,
} from 'assets/images';
import React from 'react';
// Here we have used react-icons package for the icons
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
// And react-slick as our Carousel Lib
import Slider from 'react-slick';

// Settings for the slider
const settings = {
  dots: true,
  arrows: false,
  fade: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default function CaptionCarousel() {
  // As we have used custom buttons, we need a reference variable to
  // change the state
  const [slider, setSlider] = React.useState<Slider | null>(null);

  // These are the breakpoints which changes the position of the
  // buttons as the screen size changes
  const top = useBreakpointValue({ base: '90%', md: '50%' });
  const side = useBreakpointValue({ base: '10px', md: '10px', lg: '40px' });

  // This list contains all the data for carousels
  // This can be static or loaded from a server
  const cards = [
    {
      title:
        '"The secret of my success is that we have gone to exceptional lengths to hire the best people in the world."',
      text: '-Greg Savage',
      image: workers,
    },
    {
      title: '"Hiring Tough Can Always Help You Manage Easily"',
      text: '-Sue Tetzlaff',
      image: construction_worker,
    },
    {
      title: '"Hire an attitude, not just experience and qualification."',
      text: '-Steve Jobs',
      image: worker_working_in_factory,
    },
  ];

  return (
    <Box
      position={'relative'}
      height={'600px'}
      width={'full'}
      overflow={'hidden'}>
      {/* CSS files for react-slick */}
      <link
        rel="stylesheet"
        type="text/css"
        // charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      {/* Left Icon */}
      <IconButton
      className="left-arrow"
        aria-label="left-arrow"
        variant="ghost"
        position="absolute"
        left={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => slider?.slickPrev()}>
        <BiLeftArrowAlt size="40px" />
      </IconButton>
      {/* Right Icon */}
      <IconButton
        className='right-arrow'
        aria-label="right-arrow"
        variant="ghost"
        position="absolute"
        right={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => slider?.slickNext()}>
        <BiRightArrowAlt size="40px" />
      </IconButton>
      {/* Slider */}
      <Slider {...settings} ref={(slider) => setSlider(slider)}>
        {cards.map((card, index) => (
          <Box
            className='homepage-slider-wrap'
            key={index}
            height={'6xl'}
            position="relative"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            backgroundImage={`url(${card.image})`}>
            {/* This is the block you need to change, to customize the caption */}
            <Container
              height="600px"
              position="relative"
              className="testclass"
              maxW={'800px'}>
              <Stack
                spacing={6}
                w={'100%'}
                position="absolute"
                top="50%"
                transform="translate(0, -50%)">
                <Heading width={'90%'} margin="0 auto"
                  fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}
                  color={'#fff'}
                  textShadow={'1px 1px 1px black'}
                  textTransform={'uppercase'}>
                  {card.title}
                </Heading>
                <Text width={'90%'} margin="10px auto 0 !important"
                  fontSize={{ base: 'md', lg: 'lg' }}
                  color="#fafafa"
                  textShadow={'1px 1px 1px black'}>
                  {card.text}
                </Text>
              </Stack>
            </Container>
          </Box>
        ))}
      </Slider>
    </Box>
  );
}
