import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Heading,
  HStack,
  Image,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import BlogResource from 'api/blog';
import routes from 'constants/routes';
import React from 'react';
import { useQuery } from 'react-query';
import { Navigate, useNavigate } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import { truncateString } from 'utils';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface BlogAuthorProps {
  date: Date;
  name: string;
}

export const BlogAuthor: React.FC<BlogAuthorProps> = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

const HomeBlogSection: React.FC = () => {
  const api = new BlogResource();
  const navigate = useNavigate();
  const redirectToBlogPage = () => {
    navigate('/blogs');
  };

  const { data: dataList, isLoading: isListLoading } = useQuery(
    ['blogList'],
    async () => {
      const response = await api.listBlogs();
      return response?.data;
    }
  );

  return (
    <div className="section-main-smokewhite homepage-blog-wrap">
      <Container maxW={'8xl'} p={2}>
        <Stack
          spacing={{ base: 8, md: 10 }}
          direction={'column'}
          margin={'30px auto'}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}
            p={4}
            textAlign={'center'}>
            Blogs
          </Heading>
          <Box
            className='home-blog--wrapper'
            height={'100%'}
            display={'flex'}
            flexDirection={{ base: 'row', sm: 'row', lg: 'row' }}
            justifyContent={'space-between'}
            alignItems={'flex-start'}
            flexWrap='wrap'
            margin={'auto'}>
            {dataList?.data?.slice(0, 3).map((res: any) => {
              return (
                <>
               
                  <Card
                    direction={{ base: 'row', sm: 'row' }}
                    overflow="hidden"
                    variant="outline"
                    // maxW={'380px'}
                    width={{ base:'calc(100% - 20px)', sm:'calc(50% - 20px)', lg:'calc(33.3333% - 20px)' }}
                    margin={'0 10px 10px'}
                    // minH={{ base: '', sm: '530px', lg: '530px' }}
                    display={'block'}>
                    <Link
                      textDecoration="none"
                      _hover={{ textDecoration: 'none' }}
                      href={routes.blogs.view.replace(':id', res?.id + '')}>
                        <LazyLoadImage src={
                          res?.cover_image_url
                            ? res?.cover_image_url
                            : 'https://images.unsplash.com/photo-1667489022797-ab608913feeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw5fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=800&q=60'
                        }
                        alt={res?.title}
                        objectFit="contain"
                        margin={'auto'}
                        p={'15px'}
                        loading="lazy"
                        />
                      
                    </Link>

                    <Stack
                      justifyContent={'space-between'}
                      className="blog-heading">
                      <CardBody>
                        <Heading
                          margin={'5px 0'}
                          fontSize={'2xl'}
                          marginBottom={'15px'}>
                          <Link
                            textDecoration="none"
                            _hover={{ textDecoration: 'none' }}
                            href={routes.blogs.view.replace(
                              ':id',
                              res?.id + ''
                            )}>
                            {res?.title}
                          </Link>
                        </Heading>

                        <Box className='description-wrap'
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                              truncateString(res?.description, 130) || ''
                            ),
                          }}
                          textAlign="justify"></Box>
                      </CardBody>

                      <CardFooter textTransform={'capitalize'} className='datetime' p={'5px 15px'} mt={'0px'}>
                        <BlogAuthor
                          name={res?.author}
                          date={new Date(res?.createdAt)}
                        />
                      </CardFooter>
                    </Stack>
                  </Card>
                  
                  
                </>
                
              );
            })}
          </Box>
        </Stack>
        <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: 'column', sm: 'row' }} textAlign='center' justifyContent={'center'} pb='20px'>
              <Button
              className='blog-btn'
                rounded={'full'}
                size={'lg'}
                fontWeight={'normal'}
                px={6}
                colorScheme={'red'}
                bg={'red.400'}
                _hover={{ bg: 'red.500' }}
                onClick={redirectToBlogPage}>
                See more
              </Button>
            </Stack>
        
      </Container>
      
    </div>
  );
};

export default HomeBlogSection;
