import {
  Box,
  Card,
  CardBody,
  Container,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';

import { ourTeamData } from 'constants/ourTeamList';
import React, { useEffect, useRef } from 'react';

const OurTeam: React.FC = () => {
const containerRef = useRef<any>();


  useEffect(() => {
    if (window.location.pathname.includes('/our-team')) {
      window.scrollTo({
        behavior: 'smooth',
        top: containerRef?.current?.offsetTop,
      });
    }
  });

  return (
    <Container maxW={'8xl'} ref={containerRef}>
      <Stack
        spacing={{ base: 5, md: 10 }}
        direction={'column'}
        paddingTop={'20px'}
        className="ourteam-section"
        padding={'20px'}>
        <Heading
          lineHeight={1.1}
          fontWeight={600}
          fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}
          textAlign={'left'}
          width='100%'>
          <Text
            as={'span'}
            position={'relative'}
            _after={{
              content: "''",
              width: 'full',
              height: '30%',
              position: 'absolute',
              bottom: 1,
              left: 0,
              bg: 'red.400',
              zIndex: -1,
            }}>
            Our
          </Text>
          <Text as={'span'} color={'red.400'}>
            &nbsp;Teams
          </Text>
        </Heading>
        <Stack
          className="ourteam-content"
          justifyContent={'space-around'}
          display={{ base: 'block', sm: 'flex' }}
          flexDirection={'row'} flexWrap={'wrap'}
          gap="10px">
          
          {ourTeamData.map(member => (
            <Card width={{ base: '100%', sm: '100%', md: '49%', lg: '30%' }} key={member.name}>
            <CardBody display={'block'} alignItems={'center'}>
              <Image
                objectFit="cover"
                src={member.image}
                alt={member.name}
                height={'300px'}
                width={'100%'}
              />
              <Box margin={'10px 0px'}>
                <Heading size="sm">{member.name}</Heading>
                <Text>{member.designation}</Text>
              </Box>
              <Text>
                {member.description}
              </Text>
            </CardBody>
          </Card>
          ))}
          


       


         
        

          {/* <Image w={'100%'} h={'40px'} src={GoogleImg} />
          <Spacer />
          <Image w={'100%'} h={'40px'} src={SamsungImg} />
          <Spacer />
          <Image w={'100%'} h={'40px'} src={GoogleImg} />
          <Spacer />
          <Image w={'100%'} h={'40px'} src={SamsungImg} />
          <Spacer />
          <Image w={'100%'} h={'40px'} src={GoogleImg} />
          <Spacer />
          <Image w={'100%'} h={'40px'} src={SamsungImg} /> */}
        </Stack>
      </Stack>
    </Container>
  );
};

export default OurTeam;
