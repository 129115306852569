import {
  Center,
  Container,
  Heading,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import galleryResource from 'api/gallery';
import React, { useState } from 'react';
import { useQuery } from 'react-query';

const MediaComponent: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [image, setImage] = useState('false');

  const api = new galleryResource();

  const { data: dataList, isLoading: isListLoading } = useQuery(
    ['galleryImageList'],
    async () => {
      const queryParams: any = {};
      const response = await api.paginateGallery(queryParams);
      return response?.data;
    }
  );

  const handleClose = () => {
    setImage('');
    onClose();
  };

  const handleImage = (value: any) => {
    setImage(value);
    onOpen();
  };

  return (
    <Container maxW={'8xl'} minH={'100vh'}>
      <Stack
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        pt={{ base: "20px", md: "40px" }}
        direction={{ base: 'column', md: 'row' }}>
        <Stack flex={1} spacing={{ base: 3, md: 5 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}>
            <Text as={'span'} color={'red.400'}>
              Media
            </Text>
          </Heading>
        </Stack>
      </Stack>
      <Stack
        direction={'row'}
        flexWrap={'wrap'}
        justifyContent={'flex-start'}
        alignItems={'center'}
        padding={'20px  0 '}>
        {!isListLoading &&
          dataList?.data?.length > 0 &&
          dataList?.data?.map((x: any) => (
            <Image
              key={x?.id}
              boxSize="150px"
              _hover={{
                cursor: 'pointer',
                opacity: 0.95,
              }}
              onClick={(e: any) => handleImage(x?.image_url)}
              src={x?.image_url}
              alt={x?.title}
              width={{ base:'calc(100% - 20px)', md:'calc(50% - 20px)', lg:'calc(33.3333% - 20px)' }}
              objectFit={'cover'}
              height={{ base:'100%', md:'300px', lg:'300px' }}
              margin={'10px !important'}
              fallbackSrc='https://via.placeholder.com/150'
            />
          ))}
        {!isListLoading && dataList?.data?.length == 0 && (
          <Center>No images found in gallery</Center>
        )}
      </Stack>
      <Modal isOpen={isOpen} onClose={handleClose} size={'2xl'}>
        <ModalOverlay />
        <ModalContent>
          <Image src={image} />
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default MediaComponent;
