import { Alert, Button, Heading, useToast } from '@chakra-ui/react';
import ImageGalleryGroup from 'api/gallery';
import { CenterSpinner } from 'components/common/CenterSpinner';
import ImageGalleryForm from 'components/imageGallery/imageGalleryForm';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
const EditImageGallery: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { id }: any = useParams();
  const queryImageGallery = useQueryClient();

  let imageGalleryGroupAPI = new ImageGalleryGroup();
  const [errMsg, setErrMsg] = useState<string>('');
  const methods = useForm<any>();

  const imageGalleryQuery = useQuery(
    [`imageGallery-${id}-edit`, id],
    () => imageGalleryGroupAPI.get(id).then((res) => res.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateImageGallery = useMutation((data: any) =>
    imageGalleryGroupAPI.update(id, data, {
      'Content-Type': 'multipart/form-data',
    })
  );

  const onSubmit = (data: any) => {
    if (data.client_id === '') data.client_id = null;
    updateImageGallery.mutate(data, {
      onSuccess: (res: any) => {
        toast({
          title: 'ImageGallery has been edited.',
          status: 'success',
          isClosable: true,
        });

        queryImageGallery.invalidateQueries('imageGalleryList');
        navigate(routes.galleries.list);
      },
      onError: (error: any) => {
        setErrMsg(error?.response?.data?.error?.message);
      },
    });
  };

  if (imageGalleryQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (imageGalleryQuery.isError) {
    return <Alert variant="danger">Invalid ID</Alert>;
  }

  return (
    <>
      <Heading size="md">Edit Image Details</Heading>
      <FormProvider {...methods}>
        <form>
          {errMsg && <p className="alert-danger p-2">{errMsg}</p>}
          <>
            <ImageGalleryForm
              ImageGalleryData={imageGalleryQuery?.data?.data}
            />
          </>
        </form>
        <Button
          mt={3}
          onClick={methods.handleSubmit(onSubmit)}
          colorScheme="blue"
          variant="solid"
          type="submit"
          disabled={methods.formState.isSubmitting}>
          Update
        </Button>
      </FormProvider>
    </>
  );
};

export default EditImageGallery;
