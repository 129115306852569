import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  AirportImg,
  ConstructionImg,
  FarmingIcon,
  HospitalIcon,
  HotelIcon,
  IndustryIcon,
  OfficeIcon,
  PoultryIcon,
  SecurityIcon,
  TechnicianIcon,
  TextileImg,
  WarehouseIcon,
} from 'assets/images';
import React from 'react';

const OurSector: React.FC = () => {
  return (
    <div className="section-main-smokewhite">
      <Container maxW={'8xl'}>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          direction={'column'}
          paddingBottom={'20px'}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}
            p={4}>
            Our Sectors
          </Heading>
          <Stack flex={4} direction={{ base: 'column', md: 'row' }}>
            <Flex color="white" className="flex-box-sector">
              <Box
                _hover={{ opacity: 1, cursor: 'default' }}
                w="150px"
                h="150px"
                display={'flex'}
                flexDirection={'column'}>
                <Image maxW={'80px'} w={'100%'} h={'auto'} src={IndustryIcon} />
                <Text>Industry</Text>
              </Box>
              <Box
                _hover={{ opacity: 1, cursor: 'default' }}
                w="150px"
                h="150px"
                display={'flex'}
                flexDirection={'column'}>
                <Image maxW={'80px'} w={'100%'} h={'auto'} src={FarmingIcon} />
                <Text>Farming</Text>
              </Box>
              <Box
                _hover={{ opacity: 1, cursor: 'default' }}
                w="150px"
                h="150px"
                display={'flex'}
                flexDirection={'column'}>
                <Image maxW={'80px'} w={'100%'} h={'auto'} src={PoultryIcon} />
                <Text>Poultry</Text>
              </Box>
              <Box
                _hover={{ opacity: 1, cursor: 'default' }}
                w="150px"
                h="150px"
                display={'flex'}
                flexDirection={'column'}>
                <Image maxW={'80px'} w={'100%'} h={'auto'} src={SecurityIcon} />
                <Text>Security Guards</Text>
              </Box>
              <Box
                _hover={{ opacity: 1, cursor: 'default' }}
                w="150px"
                h="150px"
                display={'flex'}
                flexDirection={'column'}>
                <Image maxW={'80px'} w={'100%'} h={'auto'} src={HospitalIcon} />
                <Text>Health Care</Text>
              </Box>
              <Box
                _hover={{ opacity: 1, cursor: 'default' }}
                w="150px"
                h="150px"
                display={'flex'}
                flexDirection={'column'}>
                <Image
                  maxW={'80px'}
                  w={'100%'}
                  h={'auto'}
                  src={ConstructionImg}
                />
                <Text>Construction</Text>
              </Box>
              <Box
                _hover={{ opacity: 1, cursor: 'default' }}
                w="150px"
                h="150px"
                display={'flex'}
                flexDirection={'column'}>
                <Image maxW={'80px'} w={'100%'} h={'auto'} src={TextileImg} />
                <Text>Textile</Text>
              </Box>
              <Box
                _hover={{ opacity: 1, cursor: 'default' }}
                w="150px"
                h="150px"
                display={'flex'}
                flexDirection={'column'}>
                <Image maxW={'80px'} w={'100%'} h={'auto'} src={AirportImg} />
                <Text>Airport</Text>
              </Box>
              <Box
                _hover={{ opacity: 1, cursor: 'default' }}
                w="150px"
                h="150px"
                display={'flex'}
                flexDirection={'column'}>
                <Image maxW={'80px'} w={'100%'} h={'auto'} src={HotelIcon} />
                <Text>Hotels & Resturants</Text>
              </Box>
              <Box
                _hover={{ opacity: 1, cursor: 'default' }}
                w="150px"
                h="150px"
                display={'flex'}
                flexDirection={'column'}>
                <Image
                  maxW={'80px'}
                  w={'100%'}
                  h={'auto'}
                  src={TechnicianIcon}
                />
                <Text>Technician / Experts</Text>
              </Box>
              <Box
                _hover={{ opacity: 1, cursor: 'default' }}
                w="150px"
                h="150px"
                display={'flex'}
                flexDirection={'column'}>
                <Image maxW={'80px'} w={'100%'} h={'auto'} src={OfficeIcon} />
                <Text>Office Worker</Text>
              </Box>
              <Box
                _hover={{ opacity: 1, cursor: 'default' }}
                w="150px"
                h="150px"
                display={'flex'}
                flexDirection={'column'}>
                <Image
                  maxW={'80px'}
                  w={'100%'}
                  h={'auto'}
                  src={WarehouseIcon}
                />
                <Text>Warehouse & Logistics</Text>
              </Box>
            </Flex>
          </Stack>
        </Stack>
      </Container>
    </div>
  );
};

export default OurSector;
