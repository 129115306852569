import {
  Box,
  Button,
  Container,
  Divider,
  Image,
  Link,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import ImportantLinkResource from 'api/importantLink';
import { Logo as LogoImg } from 'assets/images';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import React, { ReactNode, useState } from 'react';
import { MdEmail, MdLocationOn, MdPhone } from 'react-icons/md';
import { useQuery } from 'react-query';

const Logo = (props: any) => {
  return <Image maxW={{ base: '80px', md: '80px' }} src={LogoImg} />;
};

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

interface FilterParams {
  currentPage: number;
  pageSize: number;
  keyword: string;
}

const FooterPage: React.FC = () => {
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: '',
  });

  const api = new ImportantLinkResource();

  const { data: dataList, isLoading: isListLoading } = useQuery(
    [
      'importantLinkList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
      };
      const response = await api.list(queryParams);
      return response?.data;
    }
  );

  return (
    <Box bg={'#46aceb'} color={'#fff'}>
      {/* <Stack direction="row" p={4} margin={'auto'} justifyContent={'center'}>
        <Divider orientation="horizontal" w={'100%'} />
      </Stack> */}
      <Container as={Stack} maxW={'6xl'} py={10} className="footer--wrapperr">
        <SimpleGrid
          templateColumns={{ sm: '1fr 1fr', md: '1fr 1fr 1fr 2fr 1fr' }}
          spacing={8}>
          <Stack spacing={5}>
            <Box
              justifyContent={'center'}
              textAlign={'center'}
              paddingLeft={'0px'}
              paddingTop={'15px'}>
              <Logo color={useColorModeValue('gray.300', 'white')} />
            </Box>
            <Box py={{ base: 2, sm: 2, md: 2, lg: 2 }}>
              <VStack pl={0} spacing={0} alignItems="flex-start">
                <Button
                  size="md"
                  variant="ghost"
                  color="#fff"
                  whiteSpace={'unset'}
                  _hover={{ border: 'none', cursor: 'default' }}
                  leftIcon={<MdPhone color="#fff" size="20px" />}
                  pl={0}>
                  +977 01 4578824 / 25
                </Button>
                <Button
                  size="md"
                  variant="ghost"
                  color="#fff"
                  whiteSpace={'unset'}
                  _hover={{ border: 'none', cursor: 'default' }}
                  leftIcon={<MdEmail color="#fff" size="20px" />}
                  pl={0}>
                  rhythmnepal@gmail.com
                </Button>
                <Button
                  size="md"
                  variant="ghost"
                  color="#fff"
                  whiteSpace={'unset'}
                  _hover={{ border: 'none', cursor: 'default' }}
                  leftIcon={<MdLocationOn color="#fff" size="20px" />}
                  pl={0}>
                  <p>
                    Surya Bikram Gyawali Marg, Battisputali-09, Kathmandu, Nepal
                  </p>
                </Button>
              </VStack>
            </Box>
          </Stack>

          <Stack align={'flex-start'} color="#fff" className="footer-inner">
            <ListHeader>Company</ListHeader>
            <Link href={'/'}>Home</Link>
            <Link href={routes.aboutUs.aboutRhythm}>About Us</Link>
            <Link href={'/recruitment-procedure'}>Recruitment Process</Link>
            <Link href={'/media'}>Gallery</Link>
            <Link href={'/blogs'}>Blogs</Link>
            <Link href={'/contact-us'}>Contact Us</Link>
          </Stack>
          <Stack align={'flex-start'} className="footer-inner">
            <ListHeader>Support</ListHeader>
            <Link href={'#'}>Help Center</Link>
            <Link href={'#'}>Terms of Service</Link>
            <Link href={'#'}>Legal</Link>
            <Link href={'#'}>Privacy Policy</Link>
            <Link href={'#'}>Status</Link>
          </Stack>
          {!isListLoading && dataList?.data?.length > 0 && (
            <Stack align={'flex-start'} className="footer-inner">
              <ListHeader>Important Links</ListHeader>

              {dataList?.data?.map((listData: any, index: number) => (
                <Link href={listData.link} key={index} target="_blank">
                  {listData.name}
                </Link>
              ))}
            </Stack>
          )}
          <Stack align={'flex-start'} className="footer-inner">
            <ListHeader>Follow Us</ListHeader>
            <Link href={'#'}>Facebook</Link>
            <Link href={'#'}>Twitter</Link>
            <Link href={'#'}>Dribbble</Link>
            <Link href={'#'}>Instagram</Link>
            <Link href={'#'}>LinkedIn</Link>
          </Stack>
        </SimpleGrid>
        <Stack direction="row" p={4} margin={'auto'} justifyContent={'center'}>
          <Divider orientation="horizontal" w={'100%'} />
        </Stack>
        <Text textAlign={'center'} color={'#fff'} className="text-center">
          © 2022 Rhythm Oversease. All rights reserved
        </Text>
      </Container>
    </Box>
  );
};

export default FooterPage;
