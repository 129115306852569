import { Container, Heading, Stack, Text } from '@chakra-ui/react';
import FooterPage from 'components/landing/Footer';
import Header from 'components/landing/Header';
import React from 'react';

const ClientLoginProcess: React.FC = () => {
  return (
    <>
      <Header />

      <Container maxW={'8xl'} h={'50vh'}>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          pt={{ base: 20, md: 28 }}
          direction={{ base: 'column', md: 'row' }}>
          <Stack
            flex={1}
            spacing={{ base: 3, md: 5 }}
            textAlign="center"
            justifyContent={'center'}>
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}>
              <Text as={'span'} color={'red.400'}>
                Client Login Process
              </Text>
            </Heading>
          </Stack>
        </Stack>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          pt={{ base: '20px', md: '28px' }}
          direction={{ base: 'column', md: 'row' }}>
          <Stack flex={1} spacing={{ base: 3, md: 5 }} textAlign="center">
            <p>
              Upon the successful finalization of our agreement, we will
              promptly send your login credentials to you via a secure email.
            </p>
            <p>
              Please contact 014578824 / 014578825 or rhythmnepal@gmail.com for
              your login credentials.
            </p>
          </Stack>
        </Stack>
      </Container>

      <FooterPage />
    </>
  );
};

export default ClientLoginProcess;
