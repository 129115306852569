import FooterPage from 'components/landing/Footer';
import Header from 'components/landing/Header';
import MediaComponent from 'components/landing/MediaComponent';
import React from 'react';

const MediaPage: React.FC = () => {
  return (
    <>
      <Header />
      <MediaComponent />
      <FooterPage />
    </>
  );
};

export default MediaPage;
