import { FormControl, FormLabel } from '@chakra-ui/form-control';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Flex,
  FormErrorMessage,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  ScaleFade,
  Stack,
  Text,
} from '@chakra-ui/react';
import { withAuthState } from 'components/hoc/auth';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiEnvelope, BiHide, BiLock, BiShow } from 'react-icons/bi';
import { Link } from 'react-router-dom';
interface Props {
  login: (email: string, pw: string) => void;
}

interface LoginFormInputs {
  email: string;
  password: string;
}

const Login: React.FC<Props> = (props) => {
  const { login } = props;
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const {
    register,
    formState: { errors },
    handleSubmit,
    formState,
  } = useForm<LoginFormInputs>();
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const onSubmit = async (data: LoginFormInputs) => {
    try {
      await login(data.email, data.password);
    } catch (e: any) {
      if (
        e?.message ===
        'You are not authorized to login. Please contact administrator'
      ) {
        setErrMsg(
          'You are not authorized to login. Please contact administrator.'
        );
      } else {
        setErrMsg('Email or password did not match.');
      }
      setIsError(true);
    }
  };

  return (
    <Box bg="gray.50">
      <Center h="90vh">
        <Stack direction="column" spacing="48">
          <Box bg="white" p="14" shadow="box" rounded="sm">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="8">
                <Stack direction="column" spacing="4">
                  <Heading as="h1" size="xl">
                    Log in to Rhythm Overseas
                  </Heading>
                  {isError && (
                    <ScaleFade in={isError}>
                      <Alert status="error">
                        <AlertIcon />
                        {errMsg}
                      </Alert>
                    </ScaleFade>
                  )}
                </Stack>
                <Stack direction="column" spacing="5">
                  <FormControl colorScheme="primary" isInvalid={!!errors.email}>
                    <FormLabel>Email</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        children={
                          <Icon color="gray.200" w="4" h="4" as={BiEnvelope} />
                        }
                      />
                      <Input
                        id="email"
                        type="email"
                        placeholder="email@company.com"
                        {...register('email', {
                          required: 'Email',
                        })}
                      />
                    </InputGroup>
                    <FormErrorMessage>
                      {errors.email && errors.email?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    colorScheme="primary"
                    isInvalid={!!errors.password}>
                    <FormLabel>Password</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        children={
                          <Icon color="gray.200" w="4" h="4" as={BiLock} />
                        }
                      />
                      <Input
                        id="password"
                        type={show ? 'text' : 'password'}
                        placeholder="Password"
                        {...register('password', {
                          required: 'Password',
                        })}
                      />
                      <InputRightElement>
                        <IconButton
                          icon={show ? <BiHide /> : <BiShow />}
                          onClick={handleClick}
                          aria-label={show ? 'Hide' : 'Show'}
                          size="sm"
                        />
                      </InputRightElement>
                    </InputGroup>

                    <FormErrorMessage>
                      {errors.password && errors.password?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl>
                    <Flex justify="center">
                      <Button variant="link" colorScheme="primary">
                        <Link to={routes.auth.forgotPassword}>
                          Forgot your password?
                        </Link>
                      </Button>
                    </Flex>
                  </FormControl>
                  <Button
                    w="full"
                    type="submit"
                    size="lg"
                    colorScheme="primary"
                    isLoading={formState.isSubmitting}>
                    LOG IN
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Center>
    </Box>
  );
};

export default withAuthState(Login);
