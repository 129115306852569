import BlogComponent from 'components/landing/blog/BlogComponent';
import FooterPage from 'components/landing/Footer';
import Header from 'components/landing/Header';
import React from 'react';

const BlogPage: React.FC = () => {
  return (
    <>
      <Header />
      <BlogComponent />
      <FooterPage />
    </>
  );
};

export default BlogPage;
