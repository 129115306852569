import Resource from 'api/resource';
import http from 'utils/http';

class GalleryResource extends Resource {
  constructor() {
    super('galleries');
  }

  /**
   * list gallery with pagination
   */

  paginateGallery(query?: any) {
    return http({
      url: '/galleries/paginate',
      params: query,
      method: 'get',
    });
  }
}

export { GalleryResource as default };
