import FooterPage from 'components/landing/Footer';
import Header from 'components/landing/Header';
import RecruitmentProcedure from 'components/landing/RecruitmentProcedure';
import React from 'react';

const RecruitmentPage: React.FC = () => {
  return (
    <>
      <Header />
      {/* <Image
        src={fewa}
        alt="Dan Abramov"
        width={'100%'}
        maxH={'450px'}
        height={'100%'}
        objectFit={'cover'}
      /> */}

      <RecruitmentProcedure />
      <FooterPage />
    </>
  );
};

export default RecruitmentPage;
