import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
} from '@chakra-ui/react';
import ClientResource from 'api/client';
import { infoStyles, labelStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

const ViewClient: React.FC = () => {
  const { id }: any = useParams();
  const api = new ClientResource();
  const navigate = useNavigate();

  const dataQuery = useQuery<any>(
    [`client${id}`, id],
    () => api.get(id).then((res) => res?.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (dataQuery.isLoading) {
    return <CenterSpinner />;
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Flex justify="space-between">
          <Stack direction="row">
            <Heading size="md">Client Detail</Heading>
          </Stack>
        </Flex>
        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <Grid
            gap="4"
            templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Name</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.company_name || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Website</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.url || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Office Number</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.office_number || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Address</FormLabel>
                <Box sx={infoStyles}>
                  {`${dataQuery?.data?.city}, ${dataQuery?.data?.state}, ${dataQuery?.data?.country}` ||
                    '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Representative Name</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.representative_name || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Email</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.email || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Contact Number</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.contact_number || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Alternative Number</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.alternate_number || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Looking For</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.looking_for || '-'}</Box>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};

export default ViewClient;
