import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  ScaleFade,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { forgotPassword } from 'api/auth';
import React from 'react';
import { useForm } from 'react-hook-form';
import { BiEnvelope } from 'react-icons/bi';
import { useMutation } from 'react-query';
import { validEmail } from 'utils/validate';

const ForgotPassword: React.FC = () => {
  const toast = useToast();
  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
  } = useForm<any>();
  const forgotMutation = useMutation((data) => forgotPassword(data), {
    onSuccess: (res: any) => {
      reset();
      toast({
        title: 'We have emailed your password reset link!',
        status: 'success',
        isClosable: true,
      });
    },
  });

  const onSubmit = (data: any) => {
    const formData: any = {
      type: 'forget_password',
      data,
    };
    forgotMutation.mutate(formData);
  };

  return (
    <Box bg="gray.50">
      <Center h="90vh">
        <Stack direction="column" spacing="8">
          <Box bg="white" p="14" shadow="box" rounded="sm">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="8">
                <Stack
                  direction="column"
                  spacing="4"
                  style={{ textAlign: 'center' }}>
                  <Heading as="h1" size="xl">
                    Reset Password
                  </Heading>
                  <Text color="gray.500">
                    Enter your email below and we will email you a temporary
                    reset link.
                  </Text>
                  <Text color="gray.500">
                    The reset link will expire after 24 hours.
                  </Text>
                  {forgotMutation.isError && (
                    <ScaleFade in={forgotMutation.isError}>
                      <Alert status="error">
                        <AlertIcon />
                        Email address not found.
                      </Alert>
                    </ScaleFade>
                  )}
                </Stack>

                <FormControl colorScheme="primary" isInvalid={!!errors.email}>
                  <FormLabel>Email</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      children={
                        <Icon color="gray.200" w="4" h="4" as={BiEnvelope} />
                      }
                    />
                    <Input
                      id="email"
                      type="email"
                      placeholder={'email@company.com'}
                      {...register('email', {
                        required: 'Email',
                        validate: (value) =>
                          validEmail(value) ||
                          'Please Provide valid Email address.',
                      })}
                    />
                  </InputGroup>
                  {/* <FormErrorMessage>
                  {errors.email && errors.email?.message}
                </FormErrorMessage> */}
                </FormControl>
                <VStack spacing="6">
                  <Button
                    w="full"
                    type="submit"
                    size="lg"
                    colorScheme="primary"
                    isLoading={forgotMutation.isLoading}>
                    SUBMIT
                  </Button>
                </VStack>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Center>
    </Box>
  );
};

export default ForgotPassword;
