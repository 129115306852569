import {
  Box,
  Card,
  CardBody,
  CardFooter,
  Container,
  Heading,
  HStack,
  Image,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import BlogResource from 'api/blog';
import routes from 'constants/routes';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import { truncateString } from 'utils';

interface BlogAuthorProps {
  date: Date;
  name: string;
}

export const BlogAuthor: React.FC<BlogAuthorProps> = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

const BlogComponent: React.FC = () => {
  const api = new BlogResource();
  const navigate = useNavigate();

  const { data: dataList, isLoading: isListLoading } = useQuery(
    ['blogList'],
    async () => {
      const response = await api.listBlogs();
      return response?.data;
    }
  );

  return (
    <Container
      maxW={'8xl'}
      p={{ lg: '30px 0', base: 2, sm: 2 }}
      m={{ lg: '5 auto', sm: '0 auto' }}>
      <Stack
        spacing={{ base: 8, md: 10 }}
        direction={'column'}
        margin={'30px auto'}>
        <Heading
          lineHeight={1.1}
          fontWeight={600}
          fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}
          p={4}
          textAlign={'center'}>
          Blogs
        </Heading>
        <Box
          display={'flex'}
          flexDirection={{ base: 'column', sm: 'row', lg: 'row' }}
          justifyContent={'space-between'}
          alignItems={'center'}
          flexWrap="wrap"
          width={'100%'}
          className="main-blog--wrapper">
          {dataList?.data?.map((res: any) => {
            return (
              <>
                <Card
                  direction={{ base: 'column', sm: 'row' }}
                  overflow="hidden"
                  variant="outline"
                  width={'100%'}
                  // minH={{ base: '', sm: '545px', lg: '545px' }}
                  display={'block'}
                  margin={'5px auto'}
                  className="blog-content--wrap">
                  <Link
                    textDecoration="none"
                    _hover={{ textDecoration: 'none' }}
                    onClick={() =>
                      navigate(routes.blogs.view.replace(':id', res?.id + ''))
                    }>
                    <Image
                      // src="https://images.unsplash.com/photo-1667489022797-ab608913feeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw5fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=800&q=60"
                      src={
                        res?.cover_image_url
                          ? res?.cover_image_url
                          : 'https://images.unsplash.com/photo-1667489022797-ab608913feeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw5fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=800&q=60'
                      }
                      alt={res?.title}
                      objectFit="contain"
                      margin={'auto'}
                      p={'15px'}
                    />
                  </Link>

                  <Stack className="blog-desc-wrap">
                    <CardBody minH={{ lg: '105px' }}>
                      <Heading margin={'5px 0'} fontSize={'2xl'}>
                        <Link
                          textDecoration="none"
                          _hover={{ textDecoration: 'none' }}
                          onClick={() =>
                            navigate(
                              routes.blogs.view.replace(':id', res?.id + '')
                            )
                          }>
                          {res?.title}
                        </Link>
                      </Heading>

                      <Box
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(
                            truncateString(res?.description, 130) || ''
                          ),
                        }}
                        textAlign="justify"></Box>
                    </CardBody>

                    <CardFooter>
                      <BlogAuthor
                        name={res?.author}
                        date={new Date(res?.createdAt)}
                      />
                    </CardFooter>
                  </Stack>
                </Card>
              </>
            );
          })}
        </Box>
      </Stack>
    </Container>
  );
};

export default BlogComponent;
