import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
} from '@chakra-ui/react';
import ImageUploader from 'components/common/ImageUploader';
import { BlogSchema } from 'constants/schema';
import { ContentState, EditorState } from 'draft-js';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useFormContext } from 'react-hook-form';

interface Props {
  listData?: any;
}

const BlogForm: React.FC<Props> = (props) => {
  const { listData } = props;
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<BlogSchema>();
  const [editorState, setEditorState] = useState<any>(
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (!listData) return;
    const blocksFromHTML = htmlToDraft(listData?.description);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    setEditorState(EditorState.createWithContent(state));
  }, [listData]);

  const handleContentStateChange = (contentState: any) => {
    setValue('description', draftToHtml(contentState));
  };

  const handleEditorChange = (state: any) => {
    setEditorState(state);
  };

  return (
    <form>
      <Stack direction="row" align="start" spacing="4">
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)']}
          flex="1">
          <GridItem>
            <FormControl isInvalid={!!errors?.title} isRequired>
              <FormLabel>{'Title'}</FormLabel>
              <Input
                {...register('title', {
                  required: 'Blog Title',
                  maxLength: {
                    value: 100,
                    message: 'Too many Character.',
                  },
                })}
                type="text"
                defaultValue={listData?.title}
                placeholder={'Blog Title'}
              />
              <FormErrorMessage>
                {errors?.title && errors?.title?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.description}>
              <FormLabel>{'Description'}</FormLabel>
              <Editor
                editorStyle={{ border: '1px solid', height: '200px' }}
                editorState={editorState}
                onEditorStateChange={handleEditorChange}
                onContentStateChange={handleContentStateChange}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
              <FormErrorMessage>
                {errors?.description && errors?.description?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.cover_image} isRequired>
              <FormLabel>Cover Image</FormLabel>
              <ImageUploader
                uploadedFiles={listData?.cover_image}
                description={'Upload Cover Image'}
                accept="image/*"
                fileKey="cover_image"
              />
            </FormControl>
            <FormErrorMessage>
              {!!errors?.cover_image && !!errors?.cover_image?.message}
            </FormErrorMessage>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.author} isRequired>
              <FormLabel>{'Author'}</FormLabel>
              <Input
                {...register('author', {})}
                type="text"
                defaultValue={listData?.author}
                placeholder={'Author'}
              />
              <FormErrorMessage>
                {errors?.author && errors?.author?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isInvalid={!!errors?.is_published}>
              <FormLabel>{'Is Published'}</FormLabel>
              <Checkbox
                {...register('is_published')}
                size="lg"
                defaultChecked={listData?.is_published}
                colorScheme="primary"></Checkbox>
              <FormErrorMessage>
                {errors?.is_published && errors?.is_published?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </form>
  );
};

export default BlogForm;
