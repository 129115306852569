import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tooltip,
  Tr,
  useToast,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import ImageGalleryResource from 'api/gallery';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  listData: any;
  index: number;
}

const ImageGalleryListItem: React.FC<Props> = (props) => {
  const { listData, index } = props;
  const imageGalleryApi = new ImageGalleryResource();
  const toast = useToast();
  const queryClient = useQueryClient();

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const deleteImageDetail = useMutation(
    () => imageGalleryApi.destroy(listData.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('GalleryImageList');
        setDeleteModalOpen(false);
        toast({
          title: 'Delete Image',
          status: 'success',
          isClosable: true,
        });
      },
    }
  );

  const onDeletePress = () => {
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteImageDetail.mutate();
  };

  return (
    <Tr key={listData.id}>
      <Td>{index}</Td>
      <Td>{listData?.title}</Td>
      <Td>
        <Wrap>
          <WrapItem>
            <Avatar src={listData?.image_url} name={listData?.title} />
          </WrapItem>
        </Wrap>
      </Td>
      <Td>
        {listData.is_visible ? (
          <CheckIcon style={{ color: 'green' }} />
        ) : (
          <CloseIcon style={{ color: 'red' }} />
        )}
      </Td>
      <Td className="action-button">
        <RouterLink
          to={routes.galleries.edit.replace(':id', listData?.id.toString())}>
          <Tooltip hasArrow label={'Edit'}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={'Edit'}
              color="blue.300"
              size="lg"
            />
          </Tooltip>
        </RouterLink>{' '}
        <Tooltip hasArrow label={'Delete'}>
          <IconButton
            icon={<BiTrash />}
            variant="link"
            aria-label={'Delete'}
            color="red.300"
            minW="6"
            size={'lg'}
            onClick={onDeletePress}
          />
        </Tooltip>
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{'Delete Image'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>Do you really want to delete?</ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteImageDetail.isLoading}>
                  Delete
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  Cancel
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default ImageGalleryListItem;
