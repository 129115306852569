import ViewBlog from 'components/blog/ViewBlog';
import Blog from 'components/landing/blog/Blog';
import routes from 'constants/routes';
import { Login } from 'pages/auth';
import ForgotPassword from 'pages/auth/ForgotPassword';
import ResetPassword from 'pages/auth/ResetPassword';
import SetPassword from 'pages/auth/SetPassword';
import BlogList from 'pages/dashboard/blog/BlogList';
import CreateBlog from 'pages/dashboard/blog/CreateBlog';
import EditBlog from 'pages/dashboard/blog/EditBlog';
import CandidateCv from 'pages/dashboard/candidate/CandidateCv';
import CandidateList from 'pages/dashboard/candidate/CandidateList';
import ViewCandidate from 'pages/dashboard/candidate/ViewCandidate';
import ClientList from 'pages/dashboard/client/ClientList';
import ViewClient from 'pages/dashboard/client/ViewClient';
import FeedbackList from 'pages/dashboard/feedback/FeedbackList';
import ViewFeedback from 'pages/dashboard/feedback/ViewFeedback';
import AddGalleryImage from 'pages/dashboard/imageGallery/AddImage';
import EditGalleryImage from 'pages/dashboard/imageGallery/EditImage';
import ListGalleryImage from 'pages/dashboard/imageGallery/ListImage';
import AddImportantLink from 'pages/dashboard/importantLink/AddImportantLinks';
import EditImportantLInk from 'pages/dashboard/importantLink/EditImportantLink';
import ListImportantLink from 'pages/dashboard/importantLink/ListImportantLink';
import LandingPage from 'pages/landing';
import AboutUs from 'pages/landing/AboutUs';
import BlogPage from 'pages/landing/BlogPage';
import CandidatePage from 'pages/landing/CandidatePage';
import ClientLoginProcess from 'pages/landing/ClientLoginProcess';
import ClientPage from 'pages/landing/ClientPage';
import ContactUs from 'pages/landing/ContactUs';
import MediaPage from 'pages/landing/MediaPage';
import RecruitmentPage from 'pages/landing/RecruitmentPage';
import TalentHuntPage from 'pages/landing/TalentHuntPage';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthenticateRoute from './AuthenticateRoute';

// Top level application router.

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Landing Page Start */}
        <Route path={routes.landing} element={<LandingPage />} />
        <Route path={routes.aboutUs.aboutRhythm} element={<AboutUs />} />
        <Route path={routes.aboutUs.messageFromFounder} element={<AboutUs />} />
        {/* <Route path={routes.aboutUs.whyRhythm} element={<AboutUs />} /> */}
        {/* <Route path={routes.aboutUs.organizationChart} element={<AboutUs />} /> */}
        <Route path={routes.aboutUs.selectionProcedure} element={<AboutUs />} />
        <Route path={routes.aboutUs.importantLicense} element={<AboutUs />} />
        <Route path={routes.aboutUs.ourTeam} element={<AboutUs />} />

        <Route path={routes.recruitment} element={<RecruitmentPage />} />
        <Route path={routes.talentHunt} element={<TalentHuntPage />} />
        <Route path={routes.client.form} element={<ClientPage />} />
        <Route path={routes.client.login} element={<ClientLoginProcess />} />
        <Route path={routes.candidateForm} element={<CandidatePage />} />
        <Route path={routes.media} element={<MediaPage />} />
        <Route path={routes.blogs.index} element={<BlogPage />} />
        <Route path={routes.blogs.view} element={<Blog />} />
        <Route path={routes.contactUs} element={<ContactUs />} />

        <Route path={routes.auth.login} element={<Login />} />
        <Route path={routes.auth.forgotPassword} element={<ForgotPassword />} />
        <Route path={routes.auth.setPassword} element={<SetPassword />} />
        <Route path={routes.auth.resetPassword} element={<ResetPassword />} />

        <Route path={routes.dashboard} element={<AuthenticateRoute />}>
          <Route path={routes.clients.list} element={<ClientList />} />
          <Route path={routes.clients.view} element={<ViewClient />} />
          <Route path={routes.candidates.list} element={<CandidateList />} />
          <Route path={routes.candidates.view} element={<ViewCandidate />} />
          <Route path={routes.candidates.cv} element={<CandidateCv />} />
          <Route path={routes.feedbacks.list} element={<FeedbackList />} />
          <Route path={routes.feedbacks.view} element={<ViewFeedback />} />
          <Route path={routes.adminBlogs.list} element={<BlogList />} />
          <Route path={routes.adminBlogs.create} element={<CreateBlog />} />
          <Route path={routes.adminBlogs.view} element={<ViewBlog />} />
          <Route path={routes.adminBlogs.edit} element={<EditBlog />} />
          <Route
            path={routes.importantLinks.list}
            element={<ListImportantLink />}
          />
          <Route
            path={routes.importantLinks.create}
            element={<AddImportantLink />}
          />
          <Route
            path={routes.importantLinks.edit}
            element={<EditImportantLInk />}
          />
          <Route path={routes.galleries.list} element={<ListGalleryImage />} />
          <Route path={routes.galleries.create} element={<AddGalleryImage />} />
          <Route path={routes.galleries.edit} element={<EditGalleryImage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
