import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  Stack,
} from '@chakra-ui/react';
import CountryResource from 'api/country';
import SectorResource from 'api/sector';
import CreatedAtInput from 'components/common/CreatedAtInput';
import ImageUploader from 'components/common/ImageUploader';
import { SECTOR_OTHERS_UUID } from 'constants/common';
import { DISTRICTS, PROVINCES } from 'constants/data';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useFormContext } from 'react-hook-form';
import { Picky } from 'react-picky';
import 'react-picky/dist/picky.css';
import { useQuery } from 'react-query';
import { subtractYears } from 'utils';

const CandidateForm: React.FC<any> = (props) => {
  const {
    setCountryWillingList,
    setJobWillingList,
    setPreviousExperienceList,
  } = props;
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<any>();

  const sectorAPI = new SectorResource();
  const countryAPI = new CountryResource();

  const [districts, setDistricts] = useState<any>([]);
  const [provinceId, setProvinceId] = useState('');
  const [jobWilling, setJobWilling] = useState<any>([]);
  const [countriesList, setCountriesList] = useState<any>([]);
  const [isJobWillingInputVisible, setIsJobWillingInputVisible] =
    useState(false);
  const [sectorList, setSectorList] = useState<any>([]);
  const [previousExperience, setPreviousExperience] = useState<any>([]);
  const [
    isPreviousExperienceInputVisible,
    setIsPreviousExperienceInputVisible,
  ] = useState(false);
  const [countryWilling, setCountryWilling] = useState<any>([]);
  const [isCountryWillingInputVisible, setIsCountryWillingInputVisible] =
    useState(false);
  const listDistrict = (e: any) => {
    setProvinceId(e.target.value);
  };

  const changeCountry = (e: any) => {
    setCountryWilling(e);
    setCountryWillingList(e);
    setIsCountryWillingInputVisible(
      e.some((option: any) => option.id === 'other')
    );
  };

  const changeJobWilling = (e: any) => {
    setJobWilling(e);
    setJobWillingList(e);
    setIsJobWillingInputVisible(
      e.some((option: any) => option.id === SECTOR_OTHERS_UUID)
    );
  };

  const changePreviousExperience = (e: any) => {
    setPreviousExperience(e);
    setPreviousExperienceList(e);
    setIsPreviousExperienceInputVisible(
      e.some((option: any) => option.id === SECTOR_OTHERS_UUID)
    );
  };

  // get sector list
  useQuery(['sectorList'], async () => {
    const response = await sectorAPI.list();
    setSectorList(response?.data?.data);
  });

  // get country list
  useQuery(['countryList'], async () => {
    const response = await countryAPI.list();
    setCountriesList(response?.data?.data);
  });

  useEffect(() => {
    const provinceList = DISTRICTS.filter((province: any) => {
      if (provinceId === province.province_id) return province;
    });
    setDistricts(provinceList);
  }, [provinceId]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing="4"
      mt={5}
      w="100%"
      p={'0 30px 30px'}>
      <Grid
        gap="4"
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
        flex="1"
        w={'100%'}
        maxW={{ md: '100%' }}
        margin={'auto'}>
        <GridItem>
          <FormControl isInvalid={!!errors?.name} isRequired>
            <FormLabel>Full Name</FormLabel>
            <Input
              type="text"
              w={'100%'}
              {...register('name', {
                required: 'Please input valid name.',
              })}
            />
          </FormControl>
          <FormErrorMessage>
            {!!errors?.name && !!errors?.name?.message}
          </FormErrorMessage>
        </GridItem>

        <GridItem>
          <FormControl isInvalid={!!errors?.contact} isRequired>
            <FormLabel>Contact Number</FormLabel>
            <Input
              type="text"
              {...register('contact', {
                required: 'Please input valid contact.',
              })}
            />
          </FormControl>
          <FormErrorMessage>
            {!!errors?.contact && !!errors?.contact?.message}
          </FormErrorMessage>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.secondary_contact_number}>
            <FormLabel>Secondary Contact Number</FormLabel>
            <Input
              type="text"
              {...register('secondary_contact_number', {
                // required: 'Please input valid Secondary Contact Number.',
              })}
            />
          </FormControl>
          <FormErrorMessage>
            {!!errors?.secondary_contact_number &&
              !!errors?.secondary_contact_number?.message}
          </FormErrorMessage>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.dob} isRequired>
            <FormLabel>Date of Birth</FormLabel>
            <Controller
              control={control}
              name="dob"
              rules={{
                required: 'Date of Birth',
              }}
              render={({ field }) => (
                <ReactDatePicker
                  dateFormat={'dd/MM/yyyy'}
                  customInput={<CreatedAtInput />}
                  selected={field.value}
                  onChange={field.onChange}
                  shouldCloseOnSelect={true}
                  autoComplete="off"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  minDate={new Date('01/01/1900')}
                  maxDate={subtractYears(new Date(), 18)}
                />
              )}
            />
          </FormControl>
          <FormErrorMessage>
            {!!errors?.dob && !!errors?.dob?.message}
          </FormErrorMessage>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.passport_number} isRequired>
            <FormLabel>Passport Number</FormLabel>
            <Input
              type="text"
              {...register('passport_number', {
                required: 'Please input valid Passport Number.',
              })}
            />
          </FormControl>
          <FormErrorMessage>
            {!!errors?.passport_number && !!errors?.passport_number?.message}
          </FormErrorMessage>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.province} isRequired>
            <FormLabel>Province/State</FormLabel>
            <Select
              placeholder="Select Province"
              {...register(`province`, {
                required: 'Province',
              })}
              onChange={(e) => listDistrict(e)}>
              {PROVINCES.map((item: any) => (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormErrorMessage>
            {!!errors?.province && !!errors?.province?.message}
          </FormErrorMessage>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.district} isRequired>
            <FormLabel>District</FormLabel>
            {/* <Input
              type="text"
              {...register('district', {
                required: 'Please input valid District.',
              })}
            /> */}
            <Select
              placeholder="Select District"
              {...register(`district`, {
                required: 'District',
              })}>
              {districts &&
                districts.length > 0 &&
                districts.map((item: any) => (
                  <option value={item?.id} key={item?.id}>
                    {item?.name}
                  </option>
                ))}
            </Select>
          </FormControl>
          <FormErrorMessage>
            {!!errors?.district && !!errors?.district?.message}
          </FormErrorMessage>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.city} isRequired>
            <FormLabel>Village/City/Town</FormLabel>
            <Input
              type="text"
              {...register('city', {
                required: 'Please input valid Village/City/Town.',
              })}
            />
          </FormControl>
          <FormErrorMessage>
            {!!errors?.city && !!errors?.city?.message}
          </FormErrorMessage>
        </GridItem>

        <GridItem>
          <FormControl isInvalid={!!errors?.email}>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              {...register('email', {
                // required: 'Please input valid Email.',
              })}
            />
          </FormControl>
          <FormErrorMessage>
            {!!errors?.email && !!errors?.email?.message}
          </FormErrorMessage>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.job_description} isRequired>
            <FormLabel>Job Willing</FormLabel>
            <Picky
              id="picky"
              options={sectorList}
              value={jobWilling}
              onChange={changeJobWilling}
              valueKey="id"
              labelKey="name"
              multiple={true}
              includeSelectAll={false}
              includeFilter={true}
              dropdownHeight={600}
            />

            {isJobWillingInputVisible && (
              <Input
                type="text"
                placeholder="Other job Name"
                {...register('other_job_willing', {
                  required: 'Please input valid job name.',
                })}
              />
            )}
          </FormControl>
          <FormErrorMessage>
            {!!errors?.job_description && !!errors?.job_description?.message}
          </FormErrorMessage>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.country_willing} isRequired>
            <FormLabel>Country Willing</FormLabel>
            <Picky
              id="picky"
              options={countriesList}
              value={countryWilling}
              onChange={changeCountry}
              valueKey="id"
              labelKey="name"
              multiple={true}
              includeSelectAll={false}
              includeFilter={true}
              dropdownHeight={600}
            />
            {isCountryWillingInputVisible && (
              <Input
                type="text"
                placeholder="Other Country Name"
                {...register('other_country', {
                  required: 'Please input valid Country name.',
                })}
              />
            )}
          </FormControl>
          <FormErrorMessage>
            {!!errors?.country_willing && !!errors?.country_willing?.message}
          </FormErrorMessage>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.previous_experience}>
            <FormLabel>Previous Experience</FormLabel>
            <Picky
              id="picky"
              options={sectorList}
              value={previousExperience}
              onChange={changePreviousExperience}
              valueKey="id"
              labelKey="name"
              multiple={true}
              includeSelectAll={false}
              includeFilter={true}
              dropdownHeight={600}
            />
            {isPreviousExperienceInputVisible && (
              <Input
                type="text"
                placeholder="Other Previous Experience"
                {...register('other_previous_experience', {
                  required: 'Please input valid job name.',
                })}
              />
            )}
          </FormControl>
          <FormErrorMessage>
            {!!errors?.previous_experience &&
              !!errors?.previous_experience?.message}
          </FormErrorMessage>
        </GridItem>

        {/* Uploader */}
        <GridItem className="upload--wrapper">
          <FormControl isInvalid={!!errors?.pp_size_photo}>
            <FormLabel>PP Size Photo</FormLabel>
            <ImageUploader
              description={'Upload PP Size Photo'}
              fileKey="pp_size_photo"
            />
          </FormControl>
          <FormErrorMessage>
            {!!errors?.pp_size_photo && !!errors?.pp_size_photo?.message}
          </FormErrorMessage>
        </GridItem>
        {/* <GridItem>
          <FormControl isInvalid={!!errors?.full_size_photo} isRequired>
            <FormLabel>Full Size Photo</FormLabel>
            <ImageUploader
              description={'Upload Full Size Photo'}
              fileKey="full_size_photo"
            />
          </FormControl>
          <FormErrorMessage>
            {!!errors?.full_size_photo && !!errors?.full_size_photo?.message}
          </FormErrorMessage>
        </GridItem> */}
        <GridItem className="upload--wrapper">
          <FormControl isInvalid={!!errors?.passport_front}>
            <FormLabel>Passport Front</FormLabel>
            <ImageUploader
              description={'Upload Passport First Photo'}
              fileKey="passport_front"
            />
          </FormControl>
          <FormErrorMessage>
            {!!errors?.passport_front && !!errors?.passport_front?.message}
          </FormErrorMessage>
        </GridItem>
        <GridItem className="upload--wrapper">
          <FormControl isInvalid={!!errors?.passport_back}>
            <FormLabel>Passport Back</FormLabel>
            <ImageUploader
              description={'Upload Passport Second Page'}
              fileKey="passport_back"
            />
          </FormControl>
          <FormErrorMessage>
            {!!errors?.passport_back && !!errors?.passport_back?.message}
          </FormErrorMessage>
        </GridItem>
        <GridItem className="upload--wrapper">
          <FormControl isInvalid={!!errors?.cv}>
            <FormLabel>CV</FormLabel>
            <ImageUploader
              description={'Upload your CV'}
              type="pdf"
              fileKey="cv"
            />
          </FormControl>
          <FormErrorMessage>
            {!!errors?.cv && !!errors?.cv?.message}
          </FormErrorMessage>
        </GridItem>
      </Grid>
    </Stack>
  );
};

export default CandidateForm;
