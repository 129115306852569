import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Textarea,
  useToast,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import FeedbackResource from 'api/feedback';
import { ContactUsSideImg } from 'assets/images';
import routes from 'constants/routes';
import { ContactSchema } from 'constants/schema';
import React from 'react';
import { useForm } from 'react-hook-form';
import { BsPerson } from 'react-icons/bs';
import { MdEmail, MdLocationOn, MdOutlineEmail, MdPhone } from 'react-icons/md';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

const ContactForm: React.FC = () => {
  const api = new FeedbackResource();
  const navigate = useNavigate();
  const toast = useToast();

  const {
    register,
    formState: { errors },
    handleSubmit,
    formState,
  } = useForm<ContactSchema>();

  const FeedbackMutation = useMutation((data: any) => api.store(data), {
    onSuccess: (res: any) => {
      toast({
        title: 'Your query has been submitted',
        status: 'success',
        isClosable: true,
      });
      navigate(routes.landing);
    },
  });

  const onSubmit = async (data: ContactSchema) => {
    const newData = {
      name: data.name,
      email: data.mail,
      message: data.description,
    };
    FeedbackMutation.mutate(newData);
  };

  return (
    <Center flexWrap={'wrap'} justifyContent={'space-between'}>
      <Box
        className="contact-wrapper"
        // boxShadow={'0px 0px 6px 0px #c0b7b7'}
        color="black"
        // bg="white"
        borderRadius="sm"
        m={{ sm: 4, md: 16, lg: 10 }}>
        <Wrap>
          <WrapItem>
            <Box borderRadius="sm" marginTop={{ sm: '20px' }}>
              <Box m={8} color="#0B0E3F">
                <Heading>How can we help you?</Heading>
                <Text
                  mt={{ sm: 3, md: 3, lg: 5 }}
                  mb={{ sm: 3, md: 3, lg: 5 }}
                  color="gray.500">
                  Fill up the form below to contact. We will contact you as soon
                  as possible.
                </Text>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <VStack spacing={5}>
                    <FormControl isInvalid={!!errors.name} isRequired>
                      <FormLabel>Your Name</FormLabel>
                      <InputGroup borderColor="#E0E1E7">
                        <InputLeftElement
                          pointerEvents="none"
                          children={<BsPerson color="gray.800" />}
                        />
                        <Input
                          type="text"
                          size="md"
                          placeholder="Full name"
                          {...register('name', {
                            required: 'Your name',
                          })}
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {errors?.name && errors?.name?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.mail} isRequired>
                      <FormLabel>Mail</FormLabel>
                      <InputGroup borderColor="#E0E1E7">
                        <InputLeftElement
                          pointerEvents="none"
                          children={<MdOutlineEmail color="gray.800" />}
                        />
                        <Input
                          type="email"
                          size="md"
                          placeholder="Your email"
                          {...register('mail', {
                            required: 'Your mail',
                          })}
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {errors?.mail && errors?.mail?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.description} isRequired>
                      <FormLabel>Message</FormLabel>
                      <Textarea
                        borderColor="gray.300"
                        _hover={{
                          borderRadius: 'gray.300',
                        }}
                        placeholder="Message"
                        {...register('description', {
                          required: 'Message',
                        })}
                      />
                      <FormErrorMessage>
                        {errors?.description && errors?.description?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl float="right">
                      <Button
                        type="submit"
                        variant="solid"
                        colorScheme="blue"
                        color="white"
                        _hover={{}}>
                        Send Message
                      </Button>
                    </FormControl>
                  </VStack>
                </form>
              </Box>
            </Box>
          </WrapItem>
        </Wrap>
      </Box>
      <Box
        className="contact-right-wrapper"
        color="black"
        backgroundImage={ContactUsSideImg}
        minHeight={{ sm: '0px', md: '664px' }}
        backgroundSize={'cover'}
        padding={{ md: '105px 30px', sm: '40px' }}
        backgroundRepeat={'no-repeat'}
        backgroundPosition={'center'}
        flex="1"
        // maxWidth={{ md: '1', sm: 'unset', lg: '836px' }}
        w={'100%'}>
        <Box p={8}>
          <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 25 }}>
            <WrapItem>
              <Box>
                <Heading>Contact Us</Heading>

                <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                  <VStack pl={0} spacing={3} alignItems="flex-start">
                    <Button
                      size="md"
                      variant="ghost"
                      color="#000"
                      _hover={{ border: 'none', cursor: 'default' }}
                      leftIcon={<MdPhone color="#1970F1" size="20px" />}>
                      +977 01 4578824 / 25
                    </Button>
                    <Button
                      size="md"
                      variant="ghost"
                      color="#000"
                      _hover={{ border: 'none', cursor: 'default' }}
                      leftIcon={<MdEmail color="#1970F1" size="20px" />}>
                      rhythmnepal@gmail.com
                    </Button>
                    <Button
                      size="md"
                      variant="ghost"
                      color="#000"
                      _hover={{ border: 'none', cursor: 'default' }}
                      leftIcon={<MdLocationOn color="#1970F1" size="20px" />}>
                      Gyawali Marg, Kathmandu, Nepal
                    </Button>
                  </VStack>
                </Box>
              </Box>
            </WrapItem>
          </Wrap>
        </Box>
      </Box>
    </Center>
  );
};

export default ContactForm;
