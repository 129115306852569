import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
} from '@chakra-ui/react';
import ImageUploader from 'components/common/ImageUploader';
import React from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  ImageGalleryData?: any;
}

const ImageGalleryForm: React.FC<Props> = (props) => {
  const { ImageGalleryData } = props;

  const {
    register,
    formState: { errors },
  } = useFormContext<any>();

  return (
    <Stack direction="row" align="start" spacing="4" mt={5}>
      <Grid
        gap="4"
        templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)']}
        flex="1">
        <GridItem>
          <FormControl isInvalid={!!errors?.title} isRequired>
            <FormLabel>Title</FormLabel>
            <Input
              defaultValue={ImageGalleryData?.title}
              type="text"
              w={'500px'}
              {...register('title', {
                required: 'Please input valid title.',
              })}
            />
          </FormControl>
          <FormErrorMessage>
            {!!errors?.title && !!errors?.title?.message}
          </FormErrorMessage>
        </GridItem>
        {/* Uploader */}
        <GridItem>
          <FormControl isInvalid={!!errors?.image} isRequired>
            <FormLabel>Image</FormLabel>
            <ImageUploader
              description={'Upload Image'}
              accept="image/*"
              fileKey="image"
              uploadedFiles={ImageGalleryData?.image_url}
            />
          </FormControl>
          <FormErrorMessage>
            {!!errors?.image && !!errors?.image?.message}
          </FormErrorMessage>
        </GridItem>
        {/* End Uploader */}
        {/* Checkbox */}
        <GridItem>
          <Stack spacing={[1, 5]} direction={['column', 'row']}>
            <Checkbox
              {...register('is_visible')}
              size="lg"
              defaultChecked={ImageGalleryData?.is_visible}
              colorScheme="primary">
              Status
            </Checkbox>
            <FormErrorMessage>
              {!!errors?.image && !!errors?.image?.message}
            </FormErrorMessage>
          </Stack>
        </GridItem>
        {/* End Checkbox */}
      </Grid>
    </Stack>
  );
};

export default ImageGalleryForm;
