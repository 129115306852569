import http from 'utils/http';

export function login(data: any) {
  return http({
    url: '/auth/login',
    method: 'post',
    data: data,
  });
}

export function logout(refreshToken: string) {
  return http({
    url: '/auth/logout',
    method: 'post',
    data: { refresh_token: refreshToken },
  });
}

export function forgotPassword(data: any) {
  return http({
    url: '/auth/send-verification-code/',
    method: 'post',
    data: data,
  });
}

export function changePassword(data: any, headers: any) {
  return http({
    url: '/auth/password/change/',
    method: 'post',
    data: data,
    headers,
  });
}

export function resetPassword(data: any) {
  return http({
    url: '/auth/password/reset/confirm/',
    method: 'post',
    data: data,
  });
}

export function setNewPassword(data: any) {
  return http({
    url: '/auth/set-password',
    method: 'post',
    data: data,
  });
}

export function verifyEmail(data: any) {
  return http({
    url: '/auth/verify-email',
    method: 'post',
    data: data,
  });
}
