import { Box } from '@chakra-ui/react';
import WhyRhythm from 'components/landing/about/WhyRhythm';
import HomeBlogSection from 'components/landing/blog/HomeBlogSection';
import FooterPage from 'components/landing/Footer';
import Header from 'components/landing/Header';
import IntroductionPage from 'components/landing/Introdution';
import LetsTalk from 'components/landing/LetsTalk';
import OurCertificate from 'components/landing/OurCertificate';
import OurSector from 'components/landing/OurSector';
import CaptionCarousel from 'components/landing/Slider';
import Testimonials from 'components/landing/Testimonials';
import React from 'react';

const LandingPage: React.FC = () => {
  return (
    <>
      <Header />
      <CaptionCarousel />
      <Box p={0}>
        <IntroductionPage />
        <OurSector />
        <WhyRhythm />
        <HomeBlogSection />
        <OurCertificate />
        <Testimonials />
        <LetsTalk />
      </Box>
      <FooterPage />
    </>
  );
};

export default LandingPage;
