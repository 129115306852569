const routes = {
  landing: '/',
  aboutUs: {
    base: '/about-us*',
    aboutRhythm: '/about-us/rhythm',
    messageFromFounder: '/about-us/message-from-founder',
    whyRhythm: '/about-us/why-rhythm',
    // organizationChart: '/about-us/organization-chart',
    ourTeam: '/about-us/our-team',
    selectionProcedure: '/about-us/selection-procedure',
    importantLicense: '/about-us/important-license',
  },
  recruitment: '/recruitment-procedure',
  blogs: {
    base: '/blogs*',
    index: '/blogs',
    view: '/blogs/:id',
  },
  adminBlogs: {
    base: '/admin-blog*',
    list: '/admin-blog/list',
    create: '/admin-blog/create',
    view: '/admin-blog/view/:id',
    edit: '/admin-blog/edit/:id',
  },
  talentHunt: '/talent-hunt',
  client: {
    base: '/client*',
    form: '/client/form',
    login: '/client/login-process',
  },
  candidateForm: '/candidate-form',
  media: '/media',
  contactUs: '/contact-us',
  dashboard: '/',
  splash: '/splash',
  auth: {
    login: '/login',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    setPassword: '/set-password',
    verifyEmail: '/verify-email',
    register: '/register',
  },
  candidates: {
    base: '/candidates/*',
    list: '/candidates/list',
    view: '/candidates/view/:id',
    cv: '/candidates/cv/:id',
  },
  clients: {
    base: '/clients/*',
    list: '/clients/list',
    view: '/clients/view/:id',
  },
  feedbacks: {
    base: '/feedbacks/*',
    list: '/feedbacks/list',
    view: '/feedbacks/view/:id',
  },
  importantLinks: {
    base: '/important-links/*',
    list: '/important-links/list',
    create: '/important-links/create',
    edit: '/important-links/edit/:id',
  },
  galleries: {
    base: '/galleries/*',
    list: '/galleries/list',
    create: '/galleries/create',
    edit: '/galleries/edit/:id',
    view: '/galleries/view/:id',
  },
};

export default routes;
