import Resource from 'api/resource';
import http from 'utils/http';

class SectorResource extends Resource {
  constructor() {
    super('sectors');
  }

  /**
   * list sector with pagination
   */

  list(query?: any) {
    return http({
      url: '/sectors/list',
      params: query,
      method: 'get',
    });
  }
}

export { SectorResource as default };
