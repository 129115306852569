import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
} from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  importantLinkData?: any;
}

const ImportantLinkForm: React.FC<Props> = (props) => {
  const { importantLinkData } = props;

  const {
    register,
    formState: { errors },
  } = useFormContext<any>();

  return (
    <Stack direction="row" align="start" spacing="4" mt={5}>
      <Grid
        gap="4"
        templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)']}
        flex="1">
        <GridItem>
          <FormControl isInvalid={!!errors?.name} isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              defaultValue={importantLinkData?.name}
              type="text"
              w={'500px'}
              {...register('name', {
                required: 'Please input valid name.',
              })}
            />
          </FormControl>
          <FormErrorMessage>
            {!!errors?.name && !!errors?.name?.message}
          </FormErrorMessage>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.link} isRequired>
            <FormLabel>Link</FormLabel>
            <Input
              defaultValue={importantLinkData?.link}
              type="text"
              w={'500px'}
              {...register('link', {
                required: 'Please input valid link.',
              })}
            />
          </FormControl>
          <FormErrorMessage>
            {!!errors?.link && !!errors?.link?.message}
          </FormErrorMessage>
        </GridItem>
      </Grid>
    </Stack>
  );
};

export default ImportantLinkForm;
