import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  ScaleFade,
  Stack,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { setNewPassword } from 'api/auth';
import routes from 'constants/routes';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiChevronLeft, BiKey } from 'react-icons/bi';
import { useMutation } from 'react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { validatePassword } from 'utils/validate';

const ResetPassword: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const token = params.get('token') || '';
  const uid = params.get('uid') || '';
  if (token === '' || uid === '') navigate(routes.auth.login);

  const newPassword = useRef({});
  const [errMsg, setErrMsg] = useState();
  const toast = useToast();
  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm();

  const setPasswordMutation = useMutation((data) => setNewPassword(data), {
    onSuccess: (res: any) => {
      toast({
        title: 'Please login with new password.',
        status: 'success',
        isClosable: true,
      });
      navigate(routes.auth.login);
    },
    onError: (error: any) => {
      if (error?.response?.data?.errors?.token)
        setErrMsg(error?.response?.data?.errors?.token[0]);
      if (error?.response?.data?.errors?.password)
        setErrMsg(error?.response?.data?.errors?.password[0]);
      if (error?.response?.data?.link)
        setErrMsg(error?.response?.data?.link[0]);
      if (error?.response?.data?.uid) setErrMsg(error?.response?.data?.uid[0]);
    },
  });
  newPassword.current = watch('new_password1', '');

  const onSubmit = (data: any) => {
    data.token = token;
    data.uid = uid;
    setPasswordMutation.mutate(data);
  };

  return (
    <Box bg="gray.50">
      <Center h="90vh">
        <Stack direction="column" spacing="8">
          <Box bg="white" p="14" shadow="box" rounded="sm">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="8">
                <Stack direction="column" spacing="4">
                  <Heading as="h1" size="xl">
                    Reset password
                  </Heading>
                  <FormControl colorScheme="primary">
                    <FormHelperText>
                      Use 8 or more characters with a mix of letters, numbers
                      and characters
                    </FormHelperText>
                  </FormControl>

                  {setPasswordMutation.isError && (
                    <ScaleFade in={setPasswordMutation.isError}>
                      <Alert status="error">
                        <AlertIcon />
                        {errMsg}
                      </Alert>
                    </ScaleFade>
                  )}
                </Stack>
                <FormControl
                  colorScheme="primary"
                  isInvalid={!!errors.new_password1}>
                  <FormLabel>Password</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      children={
                        <Icon color="gray.200" w="4" h="4" as={BiKey} />
                      }
                    />
                    <Input
                      id="new_password1"
                      type="password"
                      placeholder="Password"
                      {...register('new_password1', {
                        required: 'Password',
                        validate: (value) =>
                          validatePassword(value) ||
                          'Please provide valid password.',
                      })}
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    {/* {errors.new_password1 && errors.new_password1?.message} */}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  colorScheme="primary"
                  isInvalid={!!errors.new_password2}>
                  <FormLabel>Confirm Password</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      children={
                        <Icon color="gray.200" w="4" h="4" as={BiKey} />
                      }
                    />
                    <Input
                      id="new_password2"
                      type="password"
                      placeholder={'Confirm Password'}
                      {...register('new_password2', {
                        required: 'Confirm Password',
                        validate: (value) =>
                          value === newPassword.current ||
                          'The passwords do not match.',
                      })}
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    {/* {errors.new_password2 && errors.new_password2?.message} */}
                  </FormErrorMessage>
                </FormControl>
                <VStack spacing="6">
                  <Button
                    w="full"
                    type="submit"
                    size="lg"
                    colorScheme="primary"
                    isLoading={setPasswordMutation.isLoading}>
                    SAVE PASSWORD
                  </Button>
                  <Link to={routes.auth.login}>
                    <Button
                      variant="link"
                      color="gray.400"
                      isDisabled={setPasswordMutation.isLoading}
                      leftIcon={<Icon as={BiChevronLeft} w="6" h="6" />}>
                      Back to Login
                    </Button>
                  </Link>
                </VStack>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Center>
    </Box>
  );
};

export default ResetPassword;
