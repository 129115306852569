import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Center,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import ClientResource from 'api/client';
import FooterPage from 'components/landing/Footer';
import Header from 'components/landing/Header';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { shape1 } from 'assets/images';
import { shape2 } from 'assets/images';
import { shape3 } from 'assets/images';
import { shape4 } from 'assets/images';

const ClientPage: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const methods = useForm<any>();
  const api = new ClientResource();
  const [errorMessage, setErrorMessage] = useState<any>();

  const addClient = useMutation((data: any) => api.store(data), {
    onSuccess: (res: any) => {
      toast({
        title: 'Your request has been received.',
        status: 'success',
        isClosable: true,
      });
      navigate(routes.landing);
    },
    onError: (error: any) => {
      setErrorMessage(error?.response?.data);
    },
  });

  const onSubmit = (data: any) => {
    addClient.mutate(data);
  };

  return (
    <>
      <Header />
      <Center>
        <Box className='client-wrapper'  bg="white" shadow="box" rounded="sm" w={'100%'}>
          <div className="shape header-shape-one">
          <img src={shape1} alt="shape"/>
          </div>

          <div className="shape header-shape-two animation-one">
          <img src={shape2} alt="shape"/>
          </div> 

          <div className="shape header-shape-three animation-one">
          <img src={shape3} alt="shape"/>
          </div> 

          <div className="shape header-shape-four animation-one">
          <img src={shape4} alt="shape"/>
          </div>
          <Container maxW={'7xl'}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
                <Heading
                  lineHeight={1.2}
                  fontWeight={600}
                  fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}>
                  <Text as={'span'} color={'red.400'}>
                    Client Form
                  </Text>
                </Heading>
                <Text color={'gray.500'}></Text>
              </Stack>
            <Stack direction="column" spacing={{ base: 8, md: 10 }}>
              <Heading lineHeight={1.2}
                  fontWeight={600}
                  fontSize={{ base: '3xl', sm: '3xl', lg: '3xl' }}
                  className="client-heading">Company Detail</Heading>

              {addClient.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
              <Stack direction="row" align="start" spacing="4" mt={5}>
                <Grid
                  gap="4"
                  templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
                  flex="1">
                  <GridItem>
                    <FormControl
                      isInvalid={!!methods.formState?.errors?.company_name}
                      isRequired>
                      <FormLabel>Name</FormLabel>
                      <Input
                        type="text"
                        w={'100%'}
                        {...methods.register('company_name', {
                          required: 'Please input valid Company Name.',
                        })}
                        placeholder="Company Name"
                      />
                    </FormControl>
                    <FormErrorMessage>
                      {!!methods.formState?.errors?.company_name &&
                        !!methods.formState?.errors?.company_name?.message}
                    </FormErrorMessage>
                  </GridItem>
                  <GridItem>
                    <FormControl isInvalid={!!methods.formState?.errors?.url}>
                      <FormLabel>Website</FormLabel>
                      <Input
                        type="text"
                        {...methods.register('url', {
                          required: 'Please input valid Website.',
                        })}
                      />
                    </FormControl>
                    <FormErrorMessage>
                      {!!methods.formState?.errors?.url &&
                        !!methods.formState?.errors?.url?.message}
                    </FormErrorMessage>
                  </GridItem>
                  <GridItem>
                    <FormControl
                      isInvalid={!!methods.formState?.errors?.office_number}
                      isRequired>
                      <FormLabel>Office Tel. Number</FormLabel>
                      <Input
                        type="text"
                        {...methods.register('office_number', {
                          required: 'Please input valid Office Number.',
                        })}
                      />
                    </FormControl>
                    <FormErrorMessage>
                      {!!methods.formState?.errors?.office_number &&
                        !!methods.formState?.errors?.office_number?.message}
                    </FormErrorMessage>
                  </GridItem>

                  {/* <GridItem>
                    <FormControl
                      isInvalid={!!methods.formState?.errors?.state}
                      isRequired>
                      <FormLabel>State</FormLabel>
                      <Input
                        type="text"
                        {...methods.register('state', {
                          required: 'Please input valid State.',
                        })}
                      />
                    </FormControl>
                    <FormErrorMessage>
                      {!!methods.formState?.errors?.state &&
                        !!methods.formState?.errors?.state?.message}
                    </FormErrorMessage>
                  </GridItem> */}
                  <GridItem>
                    <FormControl
                      isInvalid={!!methods.formState?.errors?.city}
                      isRequired>
                      <FormLabel>City</FormLabel>
                      <Input
                        type="text"
                        {...methods.register('city', {
                          required: 'Please input valid City.',
                        })}
                      />
                    </FormControl>
                    <FormErrorMessage>
                      {!!methods.formState?.errors?.city &&
                        !!methods.formState?.errors?.city?.message}
                    </FormErrorMessage>
                  </GridItem>
                  <GridItem>
                    <FormControl
                      isInvalid={!!methods.formState?.errors?.country}
                      isRequired>
                      <FormLabel>Country</FormLabel>
                      <Input
                        type="text"
                        {...methods.register('country', {
                          required: 'Please input valid Country.',
                        })}
                      />
                    </FormControl>
                    <FormErrorMessage>
                      {!!methods.formState?.errors?.country &&
                        !!methods.formState?.errors?.country?.message}
                    </FormErrorMessage>
                  </GridItem>
                </Grid>
              </Stack>

              <Heading fontWeight={600}
                  fontSize={{ base: '3xl', sm: '3xl', lg: '3xl' }} mt={5}>
                Company Representative
              </Heading>

              <Stack direction="row" align="start" spacing="4" mt={5}>
                <Grid
                  gap="4"
                  templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
                  flex="1">
                  <GridItem>
                    <FormControl isRequired>
                      <FormControl
                        isInvalid={
                          !!methods.formState?.errors?.representative_name
                        }
                        isRequired>
                        <FormLabel>Name</FormLabel>
                        <Input
                          type="text"
                          {...methods.register('representative_name', {
                            required:
                              'Please input valid Company Representative Name.',
                          })}
                          placeholder="Your Name"
                        />
                      </FormControl>
                      <FormErrorMessage>
                        {!!methods.formState?.errors?.representative_name &&
                          !!methods.formState?.errors?.representative_name
                            ?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl
                      isInvalid={!!methods.formState?.errors?.email}
                      isRequired>
                      <FormLabel>Email</FormLabel>
                      <Input
                        type="email"
                        {...methods.register('email', {
                          required: 'Please input valid Email.',
                        })}
                      />
                    </FormControl>
                    <FormErrorMessage>
                      {!!methods.formState?.errors?.email &&
                        !!methods.formState?.errors?.email?.message}
                    </FormErrorMessage>
                  </GridItem>
                  <GridItem>
                    <FormControl
                      isInvalid={!!methods.formState?.errors?.contact_number}
                      isRequired>
                      <FormLabel>Contact Number</FormLabel>
                      <Input
                        type="text"
                        {...methods.register('contact_number', {
                          required: 'Please input valid Contact Number.',
                        })}
                      />
                    </FormControl>
                    <FormErrorMessage>
                      {!!methods.formState?.errors?.contact_number &&
                        !!methods.formState?.errors?.contact_number?.message}
                    </FormErrorMessage>
                  </GridItem>
                  <GridItem>
                    <FormControl
                      isInvalid={!!methods.formState?.errors?.alternate_number}>
                      <FormLabel>Alternative Number</FormLabel>
                      <Input
                        type="text"
                        {...methods.register('alternate_number', {
                          required: 'Please input valid Designation',
                        })}
                      />
                    </FormControl>
                    <FormErrorMessage>
                      {!!methods.formState?.errors?.alternate_number &&
                        !!methods.formState?.errors?.alternate_number?.message}
                    </FormErrorMessage>
                  </GridItem>
                  <GridItem>
                    <FormControl
                      isInvalid={!!methods.formState?.errors?.designation}
                      isRequired>
                      <FormLabel>Designation</FormLabel>
                      <Input
                        type="text"
                        {...methods.register('designation', {
                          required: 'Please input valid Designation.',
                        })}
                      />
                    </FormControl>
                    <FormErrorMessage>
                      {!!methods.formState?.errors?.designation &&
                        !!methods.formState?.errors?.designation?.message}
                    </FormErrorMessage>
                  </GridItem>
                  <GridItem>
                    <FormControl
                      isInvalid={!!methods.formState?.errors?.looking_for}
                      isRequired>
                      <FormLabel>Looking For</FormLabel>
                      <Textarea
                        {...methods.register('looking_for', {
                          required: 'Please input valid Looking For.',
                        })}
                      />
                    </FormControl>
                    <FormErrorMessage>
                      {!!methods.formState?.errors?.looking_for &&
                        !!methods.formState?.errors?.looking_for?.message}
                    </FormErrorMessage>
                  </GridItem>
                </Grid>
              </Stack>
              <ButtonGroup justifyContent={'center'}>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={addClient.isLoading}
                  className={"client-submit-wrapper"}>
                  Submit
                </Button>
                <Button variant="outline" onClick={() => navigate(-1)} className={"client-cancel-wrapper"}>
                  Cancel
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
          </Container>
        </Box>
      </Center>
      <FooterPage />
    </>
  );
};

export default ClientPage;
