import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import BlogResource from 'api/blog';
import BlogForm from 'components/blog/BlogForm';
import routes from 'constants/routes';
import { BlogSchema } from 'constants/schema';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

const CreateCompany: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const navigate = useNavigate();
  const methods = useForm<BlogSchema>();
  const api = new BlogResource();

  const [errorMessage, setErrorMessage] = useState<any>();

  const addData = useMutation(
    (data: any) => api.store(data, { 'Content-Type': 'multipart/form-data' }),
    {
      onSuccess: (res: any) => {
        toast({
          title: 'Blog has been created.',
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('blogList');
        navigate(routes.adminBlogs.list);
      },
      onError: (error: any) => {
        const objKey: any = Object.keys(error?.response?.data);
        if (objKey) setErrorMessage(error?.response?.data[objKey[0]][0]);
        else setErrorMessage('Blog Creation Failed.');
      },
    }
  );

  const onSubmit = (data: any) => {
    addData.mutate(data);
  };

  return (
    <Stack direction="column" spacing="4">
      <Flex justify="space-between">
        <Heading size="md">Add Blog</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {addData.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
              <BlogForm />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={addData.isLoading}>
                  Add Blog
                </Button>
                <Button variant="outline" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default CreateCompany;
