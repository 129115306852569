import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Cert1, Cert2, Cert3, Cert4 } from 'assets/images';
import React, { useEffect, useRef, useState } from 'react';

const ImportantLicense: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [image, setImage] = useState('false');

  const handleClose = () => {
    setImage('');
    onClose();
  };

  const handleImage = (value: any) => {
    setImage(value);
    onOpen();
  };

  const containerRef = useRef<any>();

  useEffect(() => {
    if (window.location.pathname.includes('/important-license')) {
      window.scrollTo({
        behavior: 'smooth',
        top: containerRef?.current?.offsetTop,
      });
    }
  });

  return (
    <Container maxW={'8xl'} ref={containerRef}>
      <Stack className="license--wrapper">
        <Heading
          lineHeight={1.1}
          fontWeight={600}
          fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}>
          <Text
            as={'span'}
            position={'relative'}
            _after={{
              content: "''",
              width: 'full',
              height: '30%',
              position: 'absolute',
              bottom: 1,
              left: 0,
              bg: 'red.400',
              zIndex: -1,
            }}>
            Important
          </Text>
          {/* <br /> */}
          <Text as={'span'} color={'red.400'}>
            &nbsp;Liscence
          </Text>
        </Heading>
        <Flex
          className='license-content-wrapper'
          flex={2}
          justify={'flex-start'}
          align={'center'}
          w={'full'}
          wrap={'wrap'}
          >
          <Box className='license-item' boxSize="xs" height={'100%'}>
            <Image
              _hover={{
                cursor: 'pointer',
                opacity: 0.95,
              }}
              onClick={(e: any) => handleImage(Cert1)}
              src={Cert1}
              alt="Dan Abramov"
            />
          </Box>
          <Box className='license-item' boxSize="xs" height={'100%'}>
            <Image
              _hover={{
                cursor: 'pointer',
                opacity: 0.95,
              }}
              onClick={(e) => handleImage(Cert4)}
              src={Cert4}
              alt="Dan Abramov"
            />
          </Box>
          <Box className='license-item' boxSize="xs" height={'100%'}>
            <Image
              _hover={{
                cursor: 'pointer',
                opacity: 0.95,
              }}
              onClick={(e) => handleImage(Cert3)}
              src={Cert3}
              alt="Dan Abramov"
            />
          </Box>
          <Box className='license-item' boxSize="xs" height={'100%'}>
            <Image
              _hover={{
                cursor: 'pointer',
                opacity: 0.95,
              }}
              onClick={(e) => handleImage(Cert2)}
              src={Cert2}
              alt="Dan Abramov"
            />
          </Box>
        </Flex>
      </Stack>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <Image src={image} />
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default ImportantLicense;
