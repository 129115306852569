import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Td, Tr } from '@chakra-ui/react';
import routes from 'constants/routes';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  listData: any;
  index: number;
}
const ClientListItem: React.FC<Props> = (props) => {
  const { listData, index } = props;
  return (
    <Tr key={listData.id}>
      <Td>{index}</Td>
      <Td>
        {
          <RouterLink
            to={routes.feedbacks.view.replace(':id', listData?.id.toString())}>
            {listData.name}
          </RouterLink>
        }
      </Td>
      <Td>{listData.email}</Td>
      <Td>{listData.message}</Td>
      <Td>
        {listData.is_replied ? (
          <CheckIcon style={{ color: 'green' }} />
        ) : (
          <CloseIcon style={{ color: 'red' }} />
        )}
      </Td>
      {/* TO DO: create actions for view and reply. once replied, change it to tick with api */}
      <Td></Td>
    </Tr>
  );
};

export default ClientListItem;
