import { Box } from '@chakra-ui/react';
import { AboutBanner } from 'assets/images';
import AboutRhythm from 'components/landing/about/AboutRhythm';
import ImportantLicense from 'components/landing/about/ImportantLicense';
import MessageFromFounder from 'components/landing/about/MessageFromFounder';
import SelectionProcedure from 'components/landing/about/SelectionProcedure';
import FooterPage from 'components/landing/Footer';
import Header from 'components/landing/Header';
import OurTeam from 'components/landing/OurTeam';
import React from 'react';

const AboutUs: React.FC = () => {
  return (
    <>
      <Header />
      <Box
        className="parallax-about-us"
        backgroundImage={AboutBanner}
        minH={{ base: '200px', sm: '300px', lg: '490px' }}
        // backgroundAttachment={'fixed'}
        // backgroundPosition={'center'}
        backgroundRepeat={'no-repeat'}
        backgroundSize={'100% '}></Box>

      <Box className="about-us---wrapper">
        <AboutRhythm />
        <MessageFromFounder />
        <OurTeam />
        {/* <WhyRhythm /> */}
        {/* <OrganizationChart /> */}
        <SelectionProcedure />
        <ImportantLicense />
      </Box>
      <FooterPage />
    </>
  );
};

export default AboutUs;
