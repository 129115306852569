import {
  Box,
  Container,
  Heading,
  HStack,
  Image,
  Link,
  Text,
} from '@chakra-ui/react';
import BlogResource from 'api/blog';
import { CenterSpinner } from 'components/common/CenterSpinner';
import routes from 'constants/routes';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { stripHTML } from 'utils';
import FooterPage from '../Footer';
import Header from '../Header';

interface BlogAuthorProps {
  date: Date;
  name: string;
}

export const BlogAuthor: React.FC<BlogAuthorProps> = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

const Blog: React.FC = () => {
  const { id }: any = useParams();
  const api = new BlogResource();
  const navigate = useNavigate();

  const infoStyles = {
    color: 'gray.600',
    fontWeight: 'normal',
  };

  const blogQuery = useQuery<any>(
    [`blog${id}`, id],
    () => api.get(id).then((res) => res?.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (blogQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (blogQuery.isError) {
    navigate(routes.blogs.index);
  }

  return (
    <>
      <Header />
      <Container
        className='blog--wrapper'
        maxW={'8xl'}
        p={{ lg: '30px 0', base: 2, sm: 2 }}
        m={{ lg: '5 auto', sm: '0 auto' }}>
        <Box
          marginTop={{ base: '20px', sm: '50' }}
          marginBottom={{ base: '20px', sm: '50' }}
          display="flex"
          flexDirection={{ base: 'column', sm: 'row' }}
          justifyContent="space-between">
          <Box
            display="flex"
            flex="1"
            marginRight="3"
            position="relative"
            alignItems="center">
            <Box
              width={{ base: '100%', sm: '85%' }}
              zIndex="2"
              marginLeft={{ base: '0', sm: '5%' }}
              marginTop="5%">
              <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
                <Image
                  borderRadius="lg"
                  src={blogQuery?.data?.cover_image_url}
                  alt={blogQuery?.data?.title}
                  objectFit="contain"
                />
              </Link>
            </Box>
            <Box zIndex="1" width="100%" position="absolute" height="100%">
              <Box backgroundSize="20px 20px" opacity="0.4" height="100%" />
            </Box>
          </Box>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="center"
            marginTop={{ base: '3', sm: '0' }}
            className='article-content-wrapper'>
            <Heading marginTop="1">
              <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
                {blogQuery?.data?.title}
              </Link>
            </Heading>
            <Box
              sx={infoStyles}
              dangerouslySetInnerHTML={{
                __html: stripHTML(blogQuery?.data?.description),
              }}></Box>
            <BlogAuthor
              name={blogQuery?.data?.author}
              date={new Date(blogQuery?.data?.createdAt)}
            />
          </Box>
        </Box>
      </Container>
      <FooterPage />
    </>
  );
};

export default Blog;
