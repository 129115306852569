import {
  Container,
  createIcon,
  Heading,
  Icon,
  IconProps,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';

const SelectionProcedure: React.FC = () => {
  const containerRef = useRef<any>();

  useEffect(() => {
    if (window.location.pathname.includes('/selection-procedure')) {
      window.scrollTo({
        behavior: 'smooth',
        top: containerRef?.current?.offsetTop,
      });
    }
  });

  return (
    <Container maxW={'8xl'} ref={containerRef}>
      <Stack
        className='selectionprocedure-wrapper'
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        direction={{ base: 'column', md: 'row' }}>
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}>
            <Text
              as={'span'}
              position={'relative'}
              _after={{
                content: "''",
                width: 'full',
                height: '30%',
                position: 'absolute',
                bottom: 1,
                left: 0,
                bg: 'red.400',
                zIndex: -1,
              }}>
              Selection
            </Text>
            {/* <br /> */}
            <Text as={'span'} color={'red.400'}>
              &nbsp;Procedure
            </Text>
          </Heading>
          <Text
            color={'gray.500'}
            textAlign={'justify'}
            padding={'0px 30px 0px 10px'}>
            <Heading as="h2" size="md">
              Information Desk
            </Heading>
            {` Rhythm Oversease Pvt. Ltd. offers its service for its clients from its clients from its main Information Service Desk from whetherany relevant information can be obtained. Moreover, candidate registration and data bank maintaingin are also carried out from the Information Service Desk (ISD). This serves as a good information system for the Company which
                help in updating the "jobseekers" record on a regular basis. It also operates "Bio-Data Bank" where candidates information is recorded and this information can be provided upon request of our valued clients. `}
            <br /> <br />
            <Heading as="h2" size="md">
              Communications
            </Heading>
            {`Department of Rhythm Oversease Pvt. Ltd. is fully computerized and networked in order to provide our client and the candidates the best and prompt service. Our staff members are always commited and ready to help its clients providing quality manpower service.`}
            <br />
            <br />
            <Heading as="h2" size="md">
              Advertisement In Daily Newspaper
            </Heading>
            {`After receiving the Letter of Demand from Employer, we apply with the Department of Labour (DOL), Govt. of Nepal for prior approval. The DOL then grants the approval after being satisfied with the crediility of terms and conditions mentioned in Demand Letter. As pwe the rules
                and regulations of government of Nepal. THis permission will allow the recruitment company to adertise the Requirement in daily and weekly newspapers. Then only we can start the recruitment process. `}
            <br />
            <br />
            <Heading as="h2" size="md">
              Selection of Candidates
            </Heading>
            {`We maintain upto date databank of potensial candidates with full informations on their skills like education, technical, know-how and experience.
            As per the client's criteria, we short list the candidates through pre-interview. During pre-screening, we short list the candidates prely on merit basis giving equal opportunity to all. The inal interview for short listed candidates will
            be conducted by the Employer themself or their representative by taking an oral, written and prctical test (online/offline). If the employer or their representatives are not available, our HR Department will take care of it.`}
            <br />
            <br />
            <Heading as="h2" size="md">
              Trade Test
            </Heading>
            {`Rhythm Oversease Pvt. Ltd. provides the technical trade test facilities and clients can select the wprkers by condicting interview and trade test at
            same place (online/offline). During the trade test, our staff member will assist clients as well as woekers with efficiency. Like translating the language of both side.`}
            <br />
            <br />
            <Heading as="h2" size="md">
              Medical Check-up
            </Heading>
            {`Only selected candidates will be sent for full mediacal examination to the hosital or clinic, recoginzed by the Dept. 
            of Health, Government of Nepal. The candidates who are physically, mentally, and medically fit for employment, are eligible to sign the employment 
            contract and therfore entitled to process for employment visa.`}
            <br />
            <br />
            <Heading as="h2" size="md">
              Orientation
            </Heading>
            {`After receiving the job offer and employment visa, the orientation classes are organised to make all worers fully aware about the country, nature of job, laws and orders, immigration policy and religion of respective country to maintain and 
            understand the cordial relation between employer and employee. The orientation class specially gives workers general knowledge
                in all most, all aspect and guides them in the field of their employment. The class also directs them to maintain good working relationshop with EMployer and motivate them to do their duties/responsibilities in professional manner.`}
            <br />
            <br />
            <Heading as="h2" size="md">
              Travel Arrangements
            </Heading>
            {`As soon as visa is endorsed in passport or paper visas are received, we make necessary travel arrangement. After the flight 
          is confirmed, we send flight detail to our clients requesting them to receive the workers at the airport.`}
          </Text>
        </Stack>
      </Stack>
    </Container>
  );
};

const PlayIcon = createIcon({
  displayName: 'PlayIcon',
  viewBox: '0 0 58 58',
  d: 'M28.9999 0.562988C13.3196 0.562988 0.562378 13.3202 0.562378 29.0005C0.562378 44.6808 13.3196 57.438 28.9999 57.438C44.6801 57.438 57.4374 44.6808 57.4374 29.0005C57.4374 13.3202 44.6801 0.562988 28.9999 0.562988ZM39.2223 30.272L23.5749 39.7247C23.3506 39.8591 23.0946 39.9314 22.8332 39.9342C22.5717 39.9369 22.3142 39.8701 22.0871 39.7406C21.86 39.611 21.6715 39.4234 21.5408 39.1969C21.4102 38.9705 21.3421 38.7133 21.3436 38.4519V19.5491C21.3421 19.2877 21.4102 19.0305 21.5408 18.8041C21.6715 18.5776 21.86 18.3899 22.0871 18.2604C22.3142 18.1308 22.5717 18.064 22.8332 18.0668C23.0946 18.0696 23.3506 18.1419 23.5749 18.2763L39.2223 27.729C39.4404 27.8619 39.6207 28.0486 39.7458 28.2713C39.8709 28.494 39.9366 28.7451 39.9366 29.0005C39.9366 29.2559 39.8709 29.507 39.7458 29.7297C39.6207 29.9523 39.4404 30.1391 39.2223 30.272Z',
});

export const Blob = (props: IconProps) => {
  return (
    <Icon
      width={'100%'}
      viewBox="0 0 578 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default SelectionProcedure;
