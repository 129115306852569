import {
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import ImportantLinkResource from 'api/importantLink';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  listData: any;
  index: number;
}

const ImportantLinkListItem: React.FC<Props> = (props) => {
  const { listData, index } = props;
  const importantLinkApi = new ImportantLinkResource();
  const toast = useToast();
  const queryClient = useQueryClient();

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const deleteUser = useMutation(() => importantLinkApi.destroy(listData.id), {
    onSuccess: () => {
      queryClient.invalidateQueries('ImportantLinkList');
      setDeleteModalOpen(false);
      toast({
        title: 'Delete Important Link',
        status: 'success',
        isClosable: true,
      });
    },
  });

  const onDeletePress = () => {
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteUser.mutate();
  };

  return (
    <Tr key={listData.id}>
      <Td>{index}</Td>
      <Td>
        <RouterLink target={'_blank'} to={listData?.link}>
          {listData?.name}
        </RouterLink>
      </Td>
      <Td>{listData?.link}</Td>
      <Td className="action-button">
        <RouterLink
          to={routes.importantLinks.edit.replace(
            ':id',
            listData?.id.toString()
          )}>
          <Tooltip hasArrow label={'Edit'}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={'Edit'}
              color="blue.300"
              size="lg"
            />
          </Tooltip>
        </RouterLink>
        <Tooltip hasArrow label={'Delete'}>
          <IconButton
            icon={<BiTrash />}
            variant="link"
            aria-label={'Delete'}
            color="red.300"
            minW="6"
            size={'lg'}
            onClick={onDeletePress}
          />
        </Tooltip>
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{'Delete Important Link'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>Do you really want to delete?</ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteUser.isLoading}>
                  Delete
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  Cancel
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default ImportantLinkListItem;
