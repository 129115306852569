import { Td, Tr } from '@chakra-ui/react';
import routes from 'constants/routes';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  listData: any;
  index: number;
}
const CandidateListItem: React.FC<Props> = (props) => {
  const { listData, index } = props;

  return (
    <Tr key={listData.id}>
      <Td>{index}</Td>
      <Td>
        <RouterLink
          to={routes.candidates.cv.replace(':id', listData?.id.toString())}>
          {listData?.name}
        </RouterLink>
      </Td>
      <Td>{listData?.contact}</Td>
      <Td>{listData?.email}</Td>
    </Tr>
  );
};

export default CandidateListItem;
