import { Alert, Button, Heading, useToast } from '@chakra-ui/react';
import ImportantLinkGroup from 'api/importantLink';
import { CenterSpinner } from 'components/common/CenterSpinner';
import ImportantLinkForm from 'components/importantLink/importantLinkForm';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
const EditImportantLInk: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { id }: any = useParams();
  const queryImportantLink = useQueryClient();

  let importantLinkGroupAPI = new ImportantLinkGroup();
  const [errMsg, setErrMsg] = useState<string>('');
  const methods = useForm<any>();

  const importantLinkQuery = useQuery(
    [`importantLink-${id}-edit`, id],
    () => importantLinkGroupAPI.get(id).then((res) => res.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateImportantLink = useMutation((data: any) =>
    importantLinkGroupAPI.update(id, data)
  );

  const onSubmit = (data: any) => {
    if (data.client_id === '') data.client_id = null;
    updateImportantLink.mutate(data, {
      onSuccess: (res: any) => {
        toast({
          title: 'ImportantLink has been edited.',
          status: 'success',
          isClosable: true,
        });

        queryImportantLink.invalidateQueries('importantLinkList');
        navigate(routes.importantLinks.list);
      },
      onError: (error: any) => {
        setErrMsg(error?.response?.data?.error?.message);
      },
    });
  };

  if (importantLinkQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (importantLinkQuery.isError) {
    return <Alert variant="danger">Invalid ID</Alert>;
  }

  return (
    <>
      <Heading size="md">Edit Important Link</Heading>
      <FormProvider {...methods}>
        <form>
          {errMsg && <p className="alert-danger p-2">{errMsg}</p>}
          <>
            <ImportantLinkForm
              importantLinkData={importantLinkQuery?.data?.data}
            />
          </>
        </form>
        <Button
          mt={3}
          onClick={methods.handleSubmit(onSubmit)}
          colorScheme="blue"
          variant="solid"
          type="submit"
          disabled={methods.formState.isSubmitting}>
          Update
        </Button>
      </FormProvider>
    </>
  );
};

export default EditImportantLInk;
