import { Container, Heading, Image, Spacer, Stack } from '@chakra-ui/react';
import { Cert1, Cert2, Cert3, Cert4 } from 'assets/images';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const OurCertificate: React.FC = () => {
  return (
    <div className="section-main-white">
      <Container maxW={'8xl'} p={2}>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          direction={'column'}
          margin={'30px auto'}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}
            p={4} className='certificate-heading'>
            Our Certificates
          </Heading>
          <Stack
            className="image-item-wrapper row" width="100%">
            <div className='image-item'>
            <LazyLoadImage src={Cert1} 
                        alt={'certificate rhythm overseas'}
                        w={'100%'} h={'200px'}
                        loading="lazy"
                        />
                        </div>
                        {/* <Image w={'100%'} h={'200px'} src={Cert1} /></div> */}
            <div className='image-item'><LazyLoadImage src={Cert3} 
                        alt={'certificate rhythm overseas'}
                        w={'100%'} h={'200px'}
                        loading="lazy"
                        />
                        </div>
            <div className='image-item'><LazyLoadImage src={Cert2} 
                        alt={'certificate rhythm overseas'}
                        w={'100%'} h={'200px'}
                        loading="lazy"
                        />
                       </div>
            <div className='image-item'><LazyLoadImage src={Cert4} 
                        alt={'certificate rhythm overseas'}
                        w={'100%'} h={'200px'}
                        loading="lazy"
                        />
                        </div>
          </Stack>
        </Stack>
      </Container>
    </div>
  );
};

export default OurCertificate;
