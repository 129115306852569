import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Center,
  Container,
  Heading,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import CandidateResource from 'api/candidate';
import CandidateForm from 'components/candidate/CandidateForm';
import FooterPage from 'components/landing/Footer';
import Header from 'components/landing/Header';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { shape1 } from 'assets/images';
import { shape2 } from 'assets/images';
import { shape3 } from 'assets/images';
import { shape4 } from 'assets/images';

const CandidatePage: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const methods = useForm<any>();

  const api = new CandidateResource();

  const [errorMessage, setErrorMessage] = useState<any>();
  const [countryWillingList, setCountryWillingList] = useState<any>([]);
  const [jobWillingList, setJobWillingList] = useState<any>([]);
  const [previousExperienceList, setPreviousExperienceList] = useState<any>([]);

  const addCandidate = useMutation(
    (data: any) => api.store(data, { 'Content-Type': 'multipart/form-data' }),
    {
      onSuccess: (res: any) => {
        toast({
          title: 'Your request has been received.',
          status: 'success',
          isClosable: true,
        });

        navigate(routes.landing);
      },
      onError: (error: any) => {
        setErrorMessage(error?.response?.data);
      },
    }
  );

  const onSubmit = (data: any) => {
    if (countryWillingList && countryWillingList.length > 0)
      data.country_willing = countryWillingList.map((x: any) => x.id);
    else data.other_country = null;
    if (jobWillingList && jobWillingList.length > 0)
      data.job_description = jobWillingList.map((x: any) => x.id);
    else data.other_job_willing = null;

    if (previousExperienceList && previousExperienceList.length > 0)
      data.previous_experience = previousExperienceList.map((x: any) => x.id);
    else data.other_previous_experience = null;

    addCandidate.mutate(data);
  };

  return (
    <>
      <Header />
      <Center>
        <Box
          bg="white"
          className="candidate-wrapper"
          shadow="box"
          rounded="sm"
          // mt={20}
          w={'100%'}>
            <div className="shape header-shape-one">
            <img src={shape1} alt="shape"/>
            </div>

            <div className="shape header-shape-two animation-one">
            <img src={shape2} alt="shape"/>
            </div> 

            <div className="shape header-shape-three animation-one">
            <img src={shape3} alt="shape"/>
            </div> 

            <div className="shape header-shape-four animation-one">
            <img src={shape4} alt="shape"/>
            </div>
          <Container maxW={'7xl'} minH={'100vh'}>
            <Stack
              align={'center'}
              spacing={{ base: 8, md: 10 }}
              // py={{ base: 20, md: 28 }}
              direction={{ base: 'column', md: 'row' }}>
              <Stack flex={1} spacing={{ base: 5, md: 10 }}>
                <Heading
                  lineHeight={1.2}
                  fontWeight={600}
                  fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}
                  className="candidate-heading">
                  <Text as={'span'} color={'red.400'}>
                    Candidate Form
                  </Text>
                </Heading>
                <Text color={'gray.500'}></Text>
              </Stack>
            </Stack>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Stack direction="column" spacing="4" alignItems={'center'}>
                  {addCandidate.isError && (
                    <Alert status="error">
                      <AlertIcon />
                      {errorMessage}
                    </Alert>
                  )}
                  <CandidateForm
                    setCountryWillingList={setCountryWillingList}
                    setJobWillingList={setJobWillingList}
                    setPreviousExperienceList={setPreviousExperienceList}
                  />

                  <ButtonGroup>
                    <Button
                      colorScheme="primary"
                      type="submit"
                      isLoading={addCandidate.isLoading}
                      className="candidate-submit-wrap">
                      Submit
                    </Button>
                    <Button
                      className="candidate-cancel-wrap"
                      variant="outline"
                      onClick={() => navigate(-1)}>
                      Cancel
                    </Button>
                  </ButtonGroup>
                </Stack>
              </form>
            </FormProvider>
          </Container>
        </Box>
      </Center>
      <FooterPage />
    </>
  );
};

export default CandidatePage;
