import { Button, Heading, useToast } from '@chakra-ui/react';
import ImageGalleryResource from 'api/gallery';
import ImageGalleryForm from 'components/imageGallery/imageGalleryForm';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

const AddImageGallery: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();

  let imageGalleryAPI = new ImageGalleryResource();
  const [errMsg, setErrMsg] = useState<string>('');
  const methods = useForm<any>();

  const addImageGallery = useMutation(
    (data: any) =>
      imageGalleryAPI.store(data, { 'Content-Type': 'multipart/form-data' }),
    {
      onSuccess: (res: any) => {
        toast({
          title: 'Image added sucessfully to galleries.',
          status: 'success',
          isClosable: true,
        });
        navigate(routes.galleries.list);
      },
      onError: (error: any, response: any) => {
        setErrMsg(error?.response?.data?.error?.message);
      },
    }
  );

  const onSubmit = (data: any) => {
    addImageGallery.mutate(data);
  };

  return (
    <>
      <Heading size="md">Add Image</Heading>
      <div className="col-md-8">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {errMsg && <p className="alert-danger p-2">{errMsg}</p>}
            <ImageGalleryForm />

            <Button
              variant="solid"
              colorScheme={'blue'}
              type="submit"
              style={{ float: 'right' }}
              disabled={methods.formState.isSubmitting}>
              Submit
            </Button>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default AddImageGallery;
