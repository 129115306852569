import { Box } from '@chakra-ui/react';
import ContactForm from 'components/landing/ContactForm';
import FooterPage from 'components/landing/Footer';
import Header from 'components/landing/Header';
import React from 'react';

const ContactUs: React.FC = () => {
  return (
    <>
      <Header />
      {/* <Image
        src={ContactUsImg}
        alt="Dan Abramov"
        width={'100%'}
        height={'400px'}
        paddingTop={'4%'}
      /> */}
      <Box bg={'#fff'}>
        <ContactForm />
      </Box>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14130.033114968146!2d85.3418035!3d27.7015888!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4b5128d8dd1fb5f2!2sRhythm%20Overseas%20Pvt.Ltd!5e0!3m2!1sen!2snp!4v1669355018478!5m2!1sen!2snp"
        width="100%"
        height="450"
        style={{ border: 0 }}
        loading="lazy"></iframe>

      <FooterPage />
    </>
  );
};

export default ContactUs;
