import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Stack,
} from '@chakra-ui/react';
import BlogResource from 'api/blog';
import { CenterSpinner } from 'components/common/CenterSpinner';
import routes from 'constants/routes';
import React from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { stripHTML } from 'utils';

const ViewBlog: React.FC = () => {
  const { id }: any = useParams();
  const navigate = useNavigate();
  const api = new BlogResource();

  const blogQuery = useQuery<any>(
    [`blog${id}`, id],
    () => api.get(id).then((res) => res?.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (blogQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (blogQuery.isError) {
    navigate(routes.adminBlogs.list);
  }

  const labelStyles = {
    color: 'gray.900',
    fontWeight: 'bold',
  };
  const infoStyles = {
    color: 'gray.600',
    fontWeight: 'normal',
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            {'Blog Details'}
          </Heading>

          <Button
            size="sm"
            colorScheme="primary"
            onClick={() => navigate(routes.adminBlogs.edit.replace(':id', id))}>
            {'Edit Blog'}
          </Button>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <Grid
            gap="4"
            templateColumns={['repeat(2, 1fr)', 'repeat(1, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{'Title'}</FormLabel>
                <Box sx={infoStyles}>{blogQuery?.data?.title}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{'Description'}</FormLabel>
                <Box
                  sx={infoStyles}
                  dangerouslySetInnerHTML={{
                    __html: stripHTML(blogQuery?.data?.description),
                  }}></Box>
              </FormControl>
            </GridItem>
            {blogQuery?.data?.cover_image && (
              <GridItem>
                <FormControl>
                  <FormLabel sx={labelStyles}>{'Cover Image'}</FormLabel>
                  <Box sx={infoStyles}>
                    <Image
                      src={blogQuery?.data?.cover_image_url}
                      objectFit="cover"
                      alt={blogQuery?.data?.title}
                      maxW={'300px'}
                    />
                  </Box>
                </FormControl>
              </GridItem>
            )}
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{'Author'}</FormLabel>
                <Box sx={infoStyles}>{blogQuery?.data?.author || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>{'Is Published?'}</FormLabel>
                <Box sx={infoStyles}>
                  {blogQuery?.data?.is_published ? 'Yes' : 'No'}
                </Box>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};

export default ViewBlog;
