import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import BlogResource from 'api/blog';
import BlogForm from 'components/blog/BlogForm';
import { CenterSpinner } from 'components/common/CenterSpinner';
import routes from 'constants/routes';
import { BlogSchema } from 'constants/schema';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

const EditBlog: React.FC = () => {
  const { id }: any = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState<any>();
  const methods = useForm<BlogSchema>();

  const api = new BlogResource();

  const blogQuery = useQuery(
    [`blog${id}-edit`, id],
    () => api.get(id).then((res) => res?.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateBlog = useMutation((data: any) =>
    api.update(id, data, { 'Content-Type': 'multipart/form-data' })
  );

  const onSubmit = (data: any) => {
    updateBlog.mutate(data, {
      onSuccess: (res: any) => {
        toast({
          title: 'Blog has been updated.',
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('blogList');
        navigate(routes.adminBlogs.list);
      },
      onError: (error: any) => {
        const objKey: any = Object.keys(error?.response?.data);
        if (objKey) setErrorMessage(error?.response?.data[objKey][0]);
        else setErrorMessage('Blog creation failed.');
      },
    });
  };

  if (blogQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (blogQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {'Blog Not Found'}
        </Alert>
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing="4">
      <Flex justify="space-between">
        <Heading size="md">{'Edit Blog'}</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {updateBlog.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
              <BlogForm listData={blogQuery.data} />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={updateBlog.isLoading}>
                  {'Update'}
                </Button>
                <Button variant="outline" onClick={() => navigate(-1)}>
                  {'Cancel'}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default EditBlog;
