import {
  Container,
  createIcon,
  Heading,
  Icon,
  IconProps,
  Stack,
  Text,
} from '@chakra-ui/react';
import { site_view_with_men } from 'assets/images';
import React, { useEffect, useRef } from 'react';

const WhyRhythm: React.FC = () => {
  const containerRef = useRef<any>();

  useEffect(() => {
    if (window.location.pathname.includes('/why-rhythm')) {
      window.scrollTo({
        behavior: 'smooth',
        top: containerRef?.current?.offsetTop,
      });
    }
  });

  return (
    <Stack
      position={'relative'}
      // maxH={'600px'}
      height={'100%'}
      display={'block'}
      // minH={{ base: '1112px', sm: '1000px', lg: '670px' }}
      className="back"
      width={'full'}
      overflow={'hidden'}
      background={'url(' + site_view_with_men + ')'}
      backgroundAttachment={'fixed'}
      backgroundPosition={'center'}
      backgroundRepeat={'no-repeat'}
      backgroundSize={'cover'}>
      <Stack
        // position={'absolute'}
        top="0"
        bottom={'0'}
        width={'100%'}
        backgroundColor={'#000000ab'}>
        <Container maxW={'8xl'} ref={containerRef} height="100%">
          {/* Why recruit with us */}
          <Stack height="100%"
            align={'center'}
            spacing={{ base: 8, md: 10 }}
            py={{ base: 20, md: 28 }}
            direction={{ base: 'column', md: 'row' }}>
            <Stack flex={1} spacing={{ base: 5, md: 10 }} 
            justify={'center'}>
              <Heading
                lineHeight={1.1}
                fontWeight={600}
                fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}>
                <Text
                  as={'span'}
                  position={'relative'}
                  color={'#e2e2e2'}
                  _after={{
                    content: "''",
                    width: 'full',
                    height: '30%',
                    position: 'absolute',
                    bottom: 1,
                    left: 0,
                    bg: 'red.400',
                    zIndex: -1,
                  }}>
                  Why Recruit from
                </Text>
                <br />
                <Text as={'span'} color={'red.400'}>
                  Rhythm
                </Text>
              </Heading>
              <Text
                color={'#fff'}
                fontSize={'17px'}
                textAlign={'justify'}
                padding={'0px 30px 0px 10px'}>
                {/* <ul>
              <li>
                Nepalese workers are hard working, intelligent, honest & sincere
                to work.
              </li>
              <li>
                High adaptability & capacity to work in various environment
              </li>
            </ul> */}
                <p>
                  Human resources, hardly anyone would disagree, are the most
                  precious assets of any orginazation whether be it a small
                  wayside groey or a multibillion dollar corporation.
                  Accourdingly successes of any organization undoubtedly depend
                  on the efficiency and commitments of its employees. Choosing a
                  most suitable person for any given job, therefore, has become
                  a single most concern and responsibility of an owner,
                  Directors or a Manager. This tremendous pressure of soucing
                  and recruiting right person for the right job is largely
                  reduced if not eliminated by, first of all, choosing the right
                  recruitment agent to do this job youre professionally and
                  efficiency as you would respect. We Rhythm Oversease Pvt.
                  Ltd., A foreign employment recruiting agency duly lucenced by
                  the Labor Ministry of Nepal Government is geared to provide
                  you the kind of a professional Personnel Recruitment service
                  you would hate to miss. Headed by the undersigned, which has a
                  decade oversease exposure in the field of recruitment,
                  especially in the hard experience and knowledge of the
                  business envirinmentof the better positions to effectively
                  cater their needs. At Rhythm Overseases, the clients
                  satisfaction comes first simply because we firmly believe in
                  strong long term business bounds that short term gains. Our
                  highly efficient and dedicated teams of staff are constancy
                  prepared to fulfill all your manpower requirements. Our
                  promise is simply try to us once. We will ensure that you
                  would never regret your decision.
                </p>{' '}
              </Text>
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </Stack>
  );
};

const PlayIcon = createIcon({
  displayName: 'PlayIcon',
  viewBox: '0 0 58 58',
  d: 'M28.9999 0.562988C13.3196 0.562988 0.562378 13.3202 0.562378 29.0005C0.562378 44.6808 13.3196 57.438 28.9999 57.438C44.6801 57.438 57.4374 44.6808 57.4374 29.0005C57.4374 13.3202 44.6801 0.562988 28.9999 0.562988ZM39.2223 30.272L23.5749 39.7247C23.3506 39.8591 23.0946 39.9314 22.8332 39.9342C22.5717 39.9369 22.3142 39.8701 22.0871 39.7406C21.86 39.611 21.6715 39.4234 21.5408 39.1969C21.4102 38.9705 21.3421 38.7133 21.3436 38.4519V19.5491C21.3421 19.2877 21.4102 19.0305 21.5408 18.8041C21.6715 18.5776 21.86 18.3899 22.0871 18.2604C22.3142 18.1308 22.5717 18.064 22.8332 18.0668C23.0946 18.0696 23.3506 18.1419 23.5749 18.2763L39.2223 27.729C39.4404 27.8619 39.6207 28.0486 39.7458 28.2713C39.8709 28.494 39.9366 28.7451 39.9366 29.0005C39.9366 29.2559 39.8709 29.507 39.7458 29.7297C39.6207 29.9523 39.4404 30.1391 39.2223 30.272Z',
});

export const Blob = (props: IconProps) => {
  return (
    <Icon
      width={'100%'}
      viewBox="0 0 578 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default WhyRhythm;
