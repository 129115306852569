// Auth
export const ACCESS_TOKEN = 'token';
export const REFRESH_TOKEN = 'refreshToken';
export const EXPIRES_IN = 'expires';
export const SECTOR_OTHERS_UUID = '1fbf75d7-75e8-4e90-b935-0efd150ea361';
// Pagination

export const DEFAULT_PAGE_SIZE = 15;

export const INITIAL_CURRENT_PAGE = 1;
export const PAGE_LIMITS = [5, 15, 30, 50, 100];

export const CSV_EXTENSION =
  '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values, .xlsx,.xls';

export const USER_HEADER = [
  'First Name',
  'Middle Name (optional)',
  'Last Name',
  'Email',
  'Username (optional)',
  'Work Email',
  'Phone',
  'Company Name',
  'Role (Admin Y/N)',
];
