import { amritChaulagain, manisMani, shanUpadhyaya, sundarKc, vinodPoudel } from "assets/images";

export const ourTeamData = [
    {
      name: 'Vinod Paudel',
      designation: 'Co-Founder',
      image: vinodPoudel,
      description: "Vinod Paudel, Co-Founder of Rhythm Overseas Pvt. Ltd, began his journey in the recruitment industry in June 2004. His exceptional leadership skills and steadfast determination to deliver top-notch services have earned Rhythm Overseas a reputation as one of Nepal&apos;s most trusted manpower recruitment agencies. Paudel&apos;s belief in quality service has solidified long-lasting relationships with clients around the world. His passion for the industry and relentless drive to succeed make him a valuable asset to the company and its clients."
    },
    {
      name: 'Sundar KC',
      designation: 'Director',
      image: sundarKc,
      description: "Sunder KC, the Director of Rhythm Overseas Pvt. Ltd, has a multi-disciplinary background in business and enjoys a strong reputation within the business community in Nepal. He owns bot a cooperative bank and a travel agency, with his primary focus being the recruitment of young individuals from remote regions of the Himalayas. His efforts in this sector are highly respected. His gracious and polite demeanour has earned him admiration from his team, and his effective management style is seen as a model in the industry. He places a strong emphasis on timely deliveries to establish and maintain strong relationships with clients."
    },
    {
      name: 'Amrit Chaulagain',
      designation: 'Executive Director',
      image: amritChaulagain,
      description: "Amrit Chaulagain is the Executive Director of Rhythm Oversea Pvt. Ltd. He is known for his ability to handle challenging field situations and his dedication to the interim staff. He consistently intervenes to resolve issues while adhering to all regulations. Since 2014, he has played a crucial role in the candidate selection process, and his expertise in orientation has been instrumental in sharpening the skills of candidates before they are employed by the company."
    },
    {
      name: 'Shankar Upadhyaya',
      designation: 'Executive Director',
      image: shanUpadhyaya,
      description: "Shankar Upadhyaya, known as Mr. Shan, is the Executive Director of Marketing at Rhythm Overseas Pvt. Ltd. His military background and degree in Physics Hons from Dibrugarh University reflect his exceptional journey. Mr. Shan&apos;s strict demeanor and unwavering commitment have greatly contributed to Rhythm Overseas&apos;s growth. Additionally, he serves as a Partner in Business Development at Asporea Manpower Consultancy, a top recruitment firm in Northeast India."
    },
    {
      name: 'Manish Mani',
      designation: 'Business Development Director (BDD)',
      image: manisMani,
      description: " Manish Mani, Business Development Director, drives company growth by identifying opportunities, forging partnerships, and leveraging expertise in sales and marketing. With a focus on market trends and consumer behavior, he ensures revenue growth and maintains the company&aposs competitive edge. His innovative strategies and leadership contribute to long-term objectives and industry leadership."
    }
  ]