export const PROVINCES = [
  { id: 'province_1', name: 'Province No. 1' },
  { id: 'province_2', name: 'Madhesh Province' },
  { id: 'province_3', name: 'Bagmati Province' },
  { id: 'province_4', name: 'Gandaki Province' },
  { id: 'province_5', name: 'Lumbini Province' },
  { id: 'province_6', name: 'Karnali Province' },
  { id: 'province_7', name: 'Sudurpashchim Province' },
];

export const DISTRICTS = [
  { id: 'achham', name: 'Achham District ', province_id: 'province_7' },
  {
    id: 'arghakhanchi',
    name: 'Arghakhanchi District ',
    province_id: 'province_5',
  },
  { id: 'baglung', name: 'Baglung District ', province_id: 'province_4' },
  { id: 'baitadi', name: 'Baitadi District ', province_id: 'province_7' },
  { id: 'bajhang', name: 'Bajhang District ', province_id: 'province_7' },
  { id: 'bajura', name: 'Bajura District ', province_id: 'province_7' },
  { id: 'banke', name: 'Banke District ', province_id: 'province_5' },
  { id: 'bara', name: 'Bara District ', province_id: 'province_2' },
  { id: 'bardiya', name: 'Bardiya District', province_id: 'province_5' },
  { id: 'bhaktapur', name: 'Bhaktapur District ', province_id: 'province_3' },
  { id: 'bhojpur', name: 'Bhojpur District ', province_id: 'province_1' },
  { id: 'chitwan', name: 'Chitwan District ', province_id: 'province_3' },
  { id: 'dadeldhura', name: 'Dadeldhura District ', province_id: 'province_7' },
  { id: 'dailekh', name: 'Dailekh District ', province_id: 'province_6' },
  { id: 'dang', name: 'Dang District ', province_id: 'province_5' },
  { id: 'darchula', name: 'Darchula Distric', province_id: 'province_7' },
  { id: 'dhanding', name: 'Dhading District ', province_id: 'province_3' },
  { id: 'dhankuta', name: 'Dhankuta District', province_id: 'province_1' },
  { id: 'dhanusha', name: 'Dhanusha District ', province_id: 'province_2' },
  { id: 'dolakha', name: 'Dolakha District ', province_id: 'province_3' },
  { id: 'dolpa', name: 'Dolpa District ', province_id: 'province_6' },
  { id: 'doti', name: 'Doti District ', province_id: 'province_7' },
  { id: 'east_rukum', name: 'Eastern Rukum ', province_id: 'province_5' },
  { id: 'gorkha', name: 'Gorkha District ', province_id: 'province_4' },
  { id: 'gulmi', name: 'Gulmi District ', province_id: 'province_5' },
  { id: 'humla', name: 'Humla District ', province_id: 'province_6' },
  { id: 'ilam', name: 'Ilam District', province_id: 'province_1' },
  { id: 'jajarkot', name: 'Jajarkot District', province_id: 'province_6' },
  { id: 'jhapa', name: 'Jhapa District', province_id: 'province_1' },
  { id: 'jumla', name: 'Jumla District ', province_id: 'province_6' },
  { id: 'kailali', name: 'Kailali District ', province_id: 'province_7' },
  { id: 'kalikot', name: 'Kalikot District ', province_id: 'province_6' },
  { id: 'kanchanpur', name: 'Kanchanpur District ', province_id: 'province_7' },
  { id: 'kapilvastu', name: 'Kapilvastu District ', province_id: 'province_5' },
  { id: 'kaski', name: 'Kaski District ', province_id: 'province_4' },
  { id: 'kathmandu', name: 'Kathmandu District ', province_id: 'province_3' },
  {
    id: 'kavrepalanchok',
    name: 'Kavrepalanchok District ',
    province_id: 'province_3',
  },
  { id: 'khotang', name: 'Khotang District', province_id: 'province_1' },
  { id: 'lalitpur', name: 'Lalitpur District ', province_id: 'province_3' },
  { id: 'lamjung', name: 'Lamjung District ', province_id: 'province_4' },
  { id: 'mahottari', name: 'Mahottari District ', province_id: 'province_2' },
  { id: 'makwanpur', name: 'Makwanpur District', province_id: 'province_3' },
  { id: 'manang', name: 'Manang District ', province_id: 'province_4' },
  { id: 'morang', name: 'Morang District', province_id: 'province_1' },
  { id: 'mugu', name: 'Mugu District ', province_id: 'province_6' },
  { id: 'mustang', name: 'Mustang District ', province_id: 'province_4' },
  { id: 'myagdi', name: 'Myagdi District ', province_id: 'province_4' },
  {
    id: 'east_nawalparasi',
    name: 'Nawalparasi (East of Bardaghat Susta) District ',
    province_id: 'province_4',
  },
  {
    id: 'west_nawalparasi',
    name: 'Nawalparasi (West of Bardaghat Susta) District ',
    province_id: 'province_5',
  },
  { id: 'nuwakot', name: 'Nuwakot District ', province_id: 'province_3' },
  {
    id: 'okhaldhunga',
    name: 'Okhaldhunga District',
    province_id: 'province_1',
  },
  { id: 'palpa', name: 'Palpa District ', province_id: 'province_5' },
  { id: 'panchthar', name: 'Panchthar District', province_id: 'province_1' },
  { id: 'parbat', name: 'Parbat District ', province_id: 'province_4' },
  { id: 'parsa', name: 'Parsa District ', province_id: 'province_2' },
  { id: 'pyuthan', name: 'Pyuthan District ', province_id: 'province_5' },
  { id: 'ramechhap', name: 'Ramechhap District ', province_id: 'province_3' },
  { id: 'rasuwa', name: 'Rasuwa District ', province_id: 'province_3' },
  { id: 'rautahat', name: 'Rautahat District ', province_id: 'province_2' },
  { id: 'rolpa', name: 'Rolpa District ', province_id: 'province_5' },
  { id: 'rupandehi', name: 'Rupandehi District ', province_id: 'province_5' },
  { id: 'salyan', name: 'Salyan District ', province_id: 'province_6' },
  {
    id: 'sankhuwasabha',
    name: 'Sankhuwasabha District',
    province_id: 'province_1',
  },
  { id: 'saptari', name: 'Saptari District', province_id: 'province_2' },
  { id: 'sarlahi', name: 'Sarlahi District ', province_id: 'province_2' },
  { id: 'sindhuli', name: 'Sindhuli District ', province_id: 'province_3' },
  {
    id: 'sindhupalchok',
    name: 'Sindhupalchok District ',
    province_id: 'province_3',
  },
  { id: 'siraha', name: 'Siraha District ', province_id: 'province_2' },
  { id: 'solukhumbu', name: 'Solukhumbu District', province_id: 'province_1' },
  { id: 'sunsari', name: 'Sunsari District', province_id: 'province_1' },
  { id: 'surkhet', name: 'Surkhet District ', province_id: 'province_6' },
  { id: 'syangja', name: 'Syangja District ', province_id: 'province_4' },
  { id: 'tanahun', name: 'Tanahun District', province_id: 'province_4' },
  { id: 'taplejung', name: 'Taplejung District', province_id: 'province_1' },
  { id: 'tehrathum', name: 'Tehrathum District', province_id: 'province_1' },
  { id: 'udayapur', name: 'Udayapur District', province_id: 'province_1' },
  {
    id: 'west_rukum',
    name: 'Western Rukum District ',
    province_id: 'province_6',
  },
];

export const COUNTRY_WILLING = [
  { id: 'bahrain', name: 'Bahrain' },
  { id: 'belgium', name: 'Belgium' },
  { id: 'bulgari', name: 'Bulgari' },
  { id: 'canada', name: 'Canada' },
  { id: 'cyprus', name: 'Cyprus' },
  { id: 'france', name: 'France' },
  { id: 'germany', name: 'Germany' },
  { id: 'greece', name: 'Greece' },
  { id: 'hungary', name: 'Hungary' },
  { id: 'india', name: 'India' },
  { id: 'italy', name: 'Italy' },
  { id: 'japan', name: 'Japan' },
  { id: 'kuwait', name: 'Kuwait' },
  { id: 'malaysia', name: 'Malaysia' },
  { id: 'malta', name: 'Malta' },
  { id: 'morocco', name: 'Morocco' },
  { id: 'oman', name: 'Oman' },
  { id: 'poland', name: 'Poland' },
  { id: 'portugal', name: 'Portugal' },
  { id: 'qatar', name: 'Qatar' },
  { id: 'romania', name: 'Romania' },
  { id: 'russia', name: 'Russia' },
  { id: 'saudi a', name: 'Saudi A' },
  { id: 'serbia', name: 'Serbia' },
  { id: 'slovakia', name: 'Slovakia' },
  { id: 'slovenia', name: 'Slovenia' },
  { id: 'thailand', name: 'Thailand' },
  { id: 'uae', name: 'UAE' },
  { id: 'uk', name: 'UK' },
  { id: 'ukraine', name: 'Ukraine' },
  { id: 'usa', name: 'USA' },
  { id: 'other', name: 'Other' },
];
