import { DISTRICTS, PROVINCES } from 'constants/data';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

/**
 * Trunacte string and add ellipsis
 * @param str
 * @returns
 */
export function truncateString(str: string, count: number = 20) {
  if (str) return str.slice(0, count) + (str.length > count ? '...' : '');
}

export function capitalizeFirstLetter(str: string) {
  if (str) return str && str[0].toUpperCase() + str.slice(1);
}

export const cleanData = (formData: any) => {
  let cleanObj = {};
  Object.keys(formData).forEach((val: any) => {
    const newVal = formData[val];
    cleanObj = newVal ? { ...cleanObj, [val]: newVal } : cleanObj;
  });
  return cleanObj;
};

/**
 * Unmask phone number before calling API endpoint
 * Input -> Output: (+49 123 123456) -> 49123123456
 *
 * @param {string} value
 * @returns {string}
 */
export const unmaskPhone = (value: string) => {
  if (!value) return value;
  return value.replace(/[^\d]/g, '');
};

/**
 * Mask phone number to display in proper format
 * Input -> Output: 49123123456 -> (+49 123 123456)
 *
 * @param {string} phone
 * @returns {string}
 */
export const maskPhone = (phone: string) => {
  if (!phone) return phone;
  const x: any = phone.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,12})/);
  phone = !x[2] ? x[1] : '(+' + x[1] + ') ' + x[2] + (x[3] ? ' ' + x[3] : '');
  return phone ? phone : '';
};

/**
 * Get starting serial number (S.N.) based on current page and page size
 * The starting S.N. is dynamic with page
 *
 * page = 1, limit = 5 ->  staring SN = 1
 * page = 2, limit = 5 ->  staring SN = 6
 *
 * @param currentPage
 * @param pageSize
 * @returns
 */
export const getStartingSerialNumber = (
  currentPage: number,
  pageSize: number
) => {
  return currentPage * pageSize - (pageSize - 1);
};

export const convertToFormData = (fData: any) => {
  const data = new FormData();

  for (const key in fData) {
    if (key === 'field') {
      data.append(key, fData[key][1]);
    } else {
      data.append(key, fData[key]);
    }
  }
  return data;
};

export const DateFormatYMD = (date: any): string => {
  if (!date) return date;
  return dayjs(date).format('YYYY-MM-DD');
};

export const DateFormatMDY = (date: any): string => {
  if (!date) return date;
  return dayjs(date).format('MM/DD/YYYY');
};

export const DateFormat = (date: any): string => {
  if (!date) return date;
  return dayjs(date).format('MM/DD/YYYY');
};

export const DateFormatTime = (dateTime: any): string => {
  if (!dateTime) return dateTime;
  return dayjs(dateTime).format('MM/DD/YYYY h:mm:ss A');
};

export const TimeFormat = (dateTime: any): string => {
  if (!dateTime) return dateTime;
  return dayjs(dateTime).format('h:mm:ss A');
};

export const getProvince = (province: string) => {
  const exist = PROVINCES.filter((res) => res?.id === province);
  if (exist) {
    return exist[0].name;
  }
  return '-';
};

export const getDistrict = (district: string) => {
  const exist = DISTRICTS.filter((res) => res?.id === district);
  if (exist) {
    return exist[0].name;
  }
  return '-';
};

export const subtractYears = (date: any, years: number) => {
  const dateCopy = new Date(date);

  dateCopy.setFullYear(date.getFullYear() - years);

  return dateCopy;
};

import { sanitize } from 'dompurify';
export const stripHTML = (html: string) => {
  var clean = sanitize(html);
  // window.console.log('Clean', clean);
  // clean = clean.replace(/<(?:.|\n)*?>/gm, '\n');
  // clean = clean.replace(/(?:(?:\r\n|\r|\n)\s*){2}/gi, '\n');
  return clean;
};
