import {
  Box,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Stack,
  VStack,
} from '@chakra-ui/react';
import CandidateResource from 'api/candidate';
import { infoStyles, labelStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import routes from 'constants/routes';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { DateFormat, getDistrict, getProvince } from 'utils';

const CandidateCv: React.FC = () => {
  const { id }: any = useParams();
  const api = new CandidateResource();
  const navigate = useNavigate();

  const dataQuery = useQuery<any>(
    [`candidate${id}`, id],
    () => api.get(id).then((res) => res?.data?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (dataQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (dataQuery.isError) {
    navigate(routes.clients.list);
  }

  const groupCountryWillings = (data: any) => {
    if (data?.length > 0) {
      const groups = data?.map((res: any) => {
        return res?.country?.name;
      });
      return groups.join(', ');
    }
    return '-';
  };

  const groupWillings = (data: any) => {
    if (data?.length > 0) {
      const groups = data?.map((res: any) => {
        return res?.sector?.name;
      });
      return groups.join(', ');
    }
    return '-';
  };

  return (
    <VStack>
      <Container
        maxW="container.sm"
        boxShadow={'0px 0px 1px black'}
        color="white"
        backgroundColor={'#ffffff'}>
        <Stack direction="column" spacing="4" backgroundColor={'#ffffff'}>
          <Flex
            justify={'space-between'}
            alignItems={'center'}
            textAlign={'center'}
            margin={'auto'}
            padding={'20px'}>
            <Heading size="md" textTransform="capitalize">
              Candidate Detail
            </Heading>
          </Flex>

          <Stack p={['3', '6']} shadow="box" rounded="sm">
            <Grid gap="4" templateColumns={['3fr 2fr']} w="100%" mb={'30px'}>
              <GridItem>
                <FormControl mb={'10px'}>
                  <FormLabel sx={labelStyles}>Name</FormLabel>
                  <Box sx={infoStyles}>{dataQuery?.data?.name || '-'}</Box>
                </FormControl>
                <FormControl mb={'10px'}>
                  <FormLabel sx={labelStyles}>Contact Number</FormLabel>
                  <Box sx={infoStyles}>{dataQuery?.data?.contact || '-'}</Box>
                </FormControl>
                <FormControl mb={'10px'}>
                  <FormLabel sx={labelStyles}>Date of Birth</FormLabel>
                  <Box sx={infoStyles}>
                    {DateFormat(dataQuery?.data?.dob) || '-'}
                  </Box>
                </FormControl>
                <FormControl mb={'10px'}>
                  <FormLabel sx={labelStyles}>Email</FormLabel>
                  <Box sx={infoStyles}>{dataQuery?.data?.email || '-'}</Box>
                </FormControl>
              </GridItem>
              <GridItem
                margin={'auto'}
                justifyContent={'center'}
                display={'flex'}>
                <FormControl mb={'10px'} alignItems={'center'}>
                  {/* <FormLabel sx={labelStyles}>PP Size Photo</FormLabel> */}
                  <Image
                    src={dataQuery?.data?.pp_size_photo_url}
                    border={'1px solid black'}
                    maxHeight={'200px'}
                    width={'auto'}
                  />
                </FormControl>
              </GridItem>
            </Grid>
            <Grid
              gap="4"
              templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
              w="100%">
              <GridItem>
                <FormControl mb={'10px'}>
                  <FormLabel sx={labelStyles}>Passport Number</FormLabel>
                  <Box sx={infoStyles}>
                    {dataQuery?.data?.passport_number || '-'}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl mb={'10px'}>
                  <FormLabel sx={labelStyles}>Province/State</FormLabel>
                  <Box sx={infoStyles}>
                    {getProvince(dataQuery?.data?.province)}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl mb={'10px'}>
                  <FormLabel sx={labelStyles}>District</FormLabel>
                  <Box sx={infoStyles}>
                    {getDistrict(dataQuery?.data?.district)}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl mb={'10px'}>
                  <FormLabel sx={labelStyles}>Village/City/Town</FormLabel>
                  <Box sx={infoStyles}>{dataQuery?.data?.city || '-'}</Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl mb={'10px'}>
                  <FormLabel sx={labelStyles}>
                    Secondary Contact Number
                  </FormLabel>
                  <Box sx={infoStyles}>
                    {dataQuery?.data?.secondary_contact_number || '-'}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl mb={'10px'}>
                  <FormLabel sx={labelStyles}>Emergency Contact Name</FormLabel>
                  <Box sx={infoStyles}>
                    {dataQuery?.data?.emergency_contact_name || '-'}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl mb={'10px'}>
                  <FormLabel sx={labelStyles}>
                    Emergency Contact Number
                  </FormLabel>
                  <Box sx={infoStyles}>
                    {dataQuery?.data?.emergency_contact_number || '-'}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl mb={'10px'}>
                  <FormLabel sx={labelStyles}>
                    Emergency Contact Relation
                  </FormLabel>
                  <Box sx={infoStyles}>
                    {dataQuery?.data?.emergency_contact_relation || '-'}
                  </Box>
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl mb={'10px'}>
                  <FormLabel sx={labelStyles}>Job Description</FormLabel>
                  <Box sx={infoStyles}>
                    {dataQuery?.data?.job_description || '-'}
                  </Box>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl mb={'10px'}>
                  <FormLabel sx={labelStyles}>Country Willing</FormLabel>
                  <Box sx={infoStyles}>
                    {groupCountryWillings(dataQuery?.data?.countries_willings)}
                  </Box>
                </FormControl>
              </GridItem>
              {dataQuery?.data?.other_country && (
                <GridItem>
                  <FormControl mb={'10px'}>
                    <FormLabel sx={labelStyles}>Other Country</FormLabel>
                    <Box sx={infoStyles}>{dataQuery?.data?.other_country}</Box>
                  </FormControl>
                </GridItem>
              )}
              <GridItem>
                <FormControl mb={'10px'}>
                  <FormLabel sx={labelStyles}>Job Willing</FormLabel>
                  <Box sx={infoStyles}>
                    {groupWillings(dataQuery?.data?.job_willings)}
                  </Box>
                </FormControl>
              </GridItem>
              {dataQuery?.data?.other_job_willing && (
                <GridItem>
                  <FormControl mb={'10px'}>
                    <FormLabel sx={labelStyles}>Other Job</FormLabel>
                    <Box sx={infoStyles}>
                      {dataQuery?.data?.other_job_willing}
                    </Box>
                  </FormControl>
                </GridItem>
              )}
              <GridItem>
                <FormControl mb={'10px'}>
                  <FormLabel sx={labelStyles}>Previous Experience</FormLabel>
                  <Box sx={infoStyles}>
                    {groupWillings(dataQuery?.data?.previous_willings)}
                  </Box>
                </FormControl>
              </GridItem>
            </Grid>
            {dataQuery?.data?.other_previous_willing && (
              <GridItem>
                <FormControl mb={'10px'}>
                  <FormLabel sx={labelStyles}>Other Previous</FormLabel>
                  <Box sx={infoStyles}>
                    {dataQuery?.data?.other_previous_willing}
                  </Box>
                </FormControl>
              </GridItem>
            )}
            <Grid
              templateColumns={['1fr 1fr 1fr']}
              w="100%"
              mb={'30px'}
              mt={'30px'}>
              <GridItem padding={'10px'}>
                <FormControl mb={'10px'}>
                  <FormLabel sx={labelStyles}>Full Size Photo</FormLabel>
                  <Image src={dataQuery?.data?.full_size_photo_url} />
                </FormControl>
              </GridItem>
              <GridItem padding={'10px'}>
                <FormControl mb={'10px'}>
                  <FormLabel sx={labelStyles}>Passport Front</FormLabel>
                  <Image src={dataQuery?.data?.passport_front_url} />
                </FormControl>
              </GridItem>
              <GridItem padding={'10px'}>
                <FormControl mb={'10px'}>
                  <FormLabel sx={labelStyles}>Passport Back</FormLabel>
                  <Image src={dataQuery?.data?.passport_back_url} />
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>
        </Stack>
      </Container>
    </VStack>
  );
};

export default CandidateCv;
