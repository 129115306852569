import {
  Avatar,
  Box,
  Container,
  Flex,
  Heading,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { client1_testimonial, daniela_testimonial } from 'assets/images';
import React, { ReactNode } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Testimonial = ({ children }: { children: ReactNode }) => {
  return <Box>{children}</Box>;
};

const TestimonialContent = ({ children }: { children: ReactNode }) => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      boxShadow={'lg'}
      p={8}
      rounded={'xl'}
      align={'center'}
      pos={'relative'}
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        borderLeft: 'solid transparent',
        borderLeftWidth: 16,
        borderRight: 'solid transparent',
        borderRightWidth: 16,
        borderTop: 'solid',
        borderTopWidth: 16,
        borderTopColor: useColorModeValue('white', 'gray.800'),
        pos: 'absolute',
        bottom: '-16px',
        left: '50%',
        transform: 'translateX(-50%)',
      }}>
      {children}
    </Stack>
  );
};

const TestimonialHeading = ({ children }: { children: ReactNode }) => {
  return (
    <Heading as={'h3'} fontSize={'xl'}>
      {children}
    </Heading>
  );
};

const TestimonialText = ({ children }: { children: ReactNode }) => {
  return (
    <Text
      textAlign={'center'}
      color={useColorModeValue('gray.600', 'gray.400')}
      fontSize={'sm'}>
      {children}
    </Text>
  );
};

const TestimonialAvatar = ({
  src,
  name,
  title,
}: {
  src: string;
  name: string;
  title: string;
}) => {
  return (
    <Flex align={'center'} mt={8} direction={'column'}>
      <Avatar src={src} title={name} mb={2} loading='lazy' />
      <Stack spacing={-1} align={'center'}>
        <Text fontWeight={600}>{name}</Text>
        <Text fontSize={'sm'} color={useColorModeValue('gray.600', 'gray.400')}>
          {title}
        </Text>
      </Stack>
    </Flex>
  );
};

const Testimonials: React.FC = () => {
  return (
    <Box bg={useColorModeValue('gray.100', 'gray.700')}>
      <Container maxW={'7xl'} py={16} as={Stack} spacing={12}>
        <Stack spacing={0} align={'center'}>
          <Heading fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}>Our Clients Speak</Heading>
          <Text>We have been working with clients around the world</Text>
        </Stack>
        <Stack
          direction={{ base: 'row', md: 'row' }}
          flexWrap='wrap'
          spacing={{ base: 10, md: 4, lg: 10 }} 
          className="testimonial-content-wrapper testimonial-reveal fade-bottom">
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Easy to Deal</TestimonialHeading>
              <TestimonialText>
                The services we received from Rhythm were truly professionals.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={client1_testimonial}
              name={'Mr. Abdulazeez '}
              title={'Westfield Engineering, Qatar'}
            />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Trust</TestimonialHeading>
              <TestimonialText>
                I have witnessed a remarkable dedication to clients at Rhythm, a
                trait not commonly found in other recruitment agencies.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={daniela_testimonial}
              name={'Ms. Daniela Rosca'}
              title={'HR Manager at Sined Novaconst SRL'}
              
            />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>
                Excellent After Service Support
              </TestimonialHeading>
              <TestimonialText>
              Rhythm handled the lack of overtime issue professionally and kept workers motivated until proper overtime was established. Appreciate their support.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={
                'https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&q=80'
              }
              name={'Jane Cooper'}
              title={'CEO at ABC Corporation'}
            />
          </Testimonial>
        </Stack>
      </Container>
    </Box>
  );
};

export default Testimonials;
