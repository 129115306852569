import Resource from 'api/resource';
import http from 'utils/http';

class BlogResource extends Resource {
  constructor() {
    super('blogs');
  }

  /**
   * list resources
   */

  listBlogs(query?: any) {
    return http({
      url: '/blogs/list',
      params: query,
      method: 'get',
    });
  }
}

export { BlogResource as default };
