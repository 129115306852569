import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { Logo } from 'assets/images';
import { withAuthState } from 'components/hoc/auth';
import routes from 'constants/routes';
import React from 'react';
import { BiLogOut } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

interface Props {
  logout: () => void;
}

const Links = [
  {
    menu: 'Candidate',
    path: routes.candidates.list,
  },
  {
    menu: 'Client',
    path: routes.clients.list,
  },
  {
    menu: 'Feedback',
    path: routes.feedbacks.list,
  },
  {
    menu: 'Blogs',
    path: routes.adminBlogs.list,
  },
  {
    menu: 'Important Link',
    path: routes.importantLinks.list,
  },
  {
    menu: 'Galleries',
    path: routes.galleries.list,
  },
];

const NavLink = (data: any) => {
  let navigate = useNavigate();
  return (
    <Link
      px={2}
      py={1}
      rounded={'md'}
      lineHeight={'16px'}
      fontWeight={'500'}
      _hover={{
        textDecoration: 'none',
      }}
      onClick={() => navigate(data?.menuMap?.path)}>
      {data?.menuMap?.menu}
    </Link>
  );
};

interface Props {
  logout: () => void;
}

const Nav: React.FC<Props> = (props) => {
  let navigate = useNavigate();
  const { logout } = props;

  const onLogoutClick = async () => {
    try {
      logout();
    } catch (err) {}
  };

  return (
    <>
      <Box bg={'#393A3B'} px={4}>
        <Flex
          h={20}
          alignItems={'center'}
          justifyContent={'space-between'}
          color={'#fff'}>
          <HStack spacing={8} alignItems={'center'}>
            <Box>
              <Image src={Logo} w={'20'} />
            </Box>
            <HStack
              as={'nav'}
              spacing={4}
              display={{ base: 'none', md: 'flex' }}>
              {Links.map((link) => (
                <NavLink key={link?.menu} menuMap={link} />
              ))}
            </HStack>
          </HStack>
          <Flex alignItems={'center'}>
            <Menu>
              <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}>
                <Avatar size={'sm'} src={''} />
              </MenuButton>
              <MenuList color={'#000'}>
                <MenuItem onClick={onLogoutClick}>
                  <IconButton
                    icon={<BiLogOut />}
                    variant="link"
                    aria-label={'Settings'}
                    color="blue.300"
                    size="lg"
                  />
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default withAuthState(Nav);
