import { Button, Heading, useToast } from '@chakra-ui/react';
import ImportantLinkResource from 'api/importantLink';
import { CenterSpinner } from 'components/common/CenterSpinner';
import ImportantLinkForm from 'components/importantLink/importantLinkForm';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

const AddImportantLink: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();

  let importantLinkAPI = new ImportantLinkResource();
  const [errMsg, setErrMsg] = useState<string>('');
  const [importantLinkData, setClientData] = useState<any>();
  const methods = useForm<any>();

  const importantLinkQuery = useQuery(
    [`get-all-importantLinks`],
    () =>
      importantLinkAPI.list().then((res) => {
        setClientData(res.data.data);
      }),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const addImportantLink = useMutation(
    (data: any) => importantLinkAPI.store(data),
    {
      onSuccess: (res: any) => {
        toast({
          title: 'ImportantLink created sucessfully.',
          status: 'success',
          isClosable: true,
        });
        navigate(routes.importantLinks.list);
      },
      onError: (error: any, response: any) => {
        setErrMsg(error?.response?.data?.error?.message);
      },
    }
  );

  const onSubmit = (data: any) => {
    addImportantLink.mutate(data);
  };

  if (importantLinkQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (importantLinkQuery.isError) {
    navigate(routes.importantLinks.list);
  }

  return (
    <>
      <Heading size="md">Create ImportantLink</Heading>
      <div className="col-md-8">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {errMsg && <p className="alert-danger p-2">{errMsg}</p>}
            <ImportantLinkForm importantLinkData={importantLinkData} />

            <Button
              variant="solid"
              colorScheme={'blue'}
              type="submit"
              mt={3}
              disabled={methods.formState.isSubmitting}>
              Submit
            </Button>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default AddImportantLink;
