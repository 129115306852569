import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import FeedbackResource from 'api/feedback';
import routes from 'constants/routes';
import { ContactSchema } from 'constants/schema';
import React from 'react';
import { useForm } from 'react-hook-form';
import { BsPerson } from 'react-icons/bs';
import { MdOutlineEmail } from 'react-icons/md';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { shape1 } from 'assets/images';
import { shape2 } from 'assets/images';
import { shape3 } from 'assets/images';
import { shape4 } from 'assets/images';


const LetsTalk: React.FC = () => {
  const api = new FeedbackResource();
  const navigate = useNavigate();
  const toast = useToast();

  const {
    register,
    formState: { errors },
    handleSubmit,
    formState,
  } = useForm<ContactSchema>();

  const FeedbackMutation = useMutation((data: any) => api.store(data), {
    onSuccess: (res: any) => {
      toast({
        title: 'Your query has been submitted',
        status: 'success',
        isClosable: true,
      });
      navigate(routes.landing);
    },
  });

  const onSubmit = async (data: ContactSchema) => {
    const newData = {
      name: data.name,
      email: data.mail,
      message: data.description,
    };
    FeedbackMutation.mutate(newData);
  };

  return (
    <Container maxW={'100%'} p={2} className="lets-talk-wrap">
      
      <div className="shape header-shape-one">
      <img src={shape1} alt="shape"/>
      </div>

      <div className="shape header-shape-two animation-one">
      <img src={shape2} alt="shape"/>
      </div> 

      <div className="shape header-shape-three animation-one">
      <img src={shape3} alt="shape"/>
      </div> 

      <div className="shape header-shape-four animation-one">
      <img src={shape4} alt="shape"/>
      </div>
      <Stack maxW={'8xl'} spacing={{ base: 8, md: 10 }}>
        <Center>
          <Box
            color="black"
            borderRadius="sm"
            m={{ sm: 4, md: 16, lg: 10 }}
            w={'100%'}
            maxW="550px">
            <Box borderRadius="sm" marginTop={{ sm: '20px' }}>
              <Box m={{ lg: 8, sm: 2 }} color="#0B0E3F">
                <Heading>{"Let's Talk"}</Heading>
                <Text
                  mt={{ sm: 3, md: 3, lg: 5 }}
                  mb={{ sm: 3, md: 3, lg: 5 }}
                  color="gray.500">
                  Fill up the form below to contact. We will contact you as soon
                  as possible.
                </Text>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <VStack spacing={5}>
                    <FormControl isInvalid={!!errors.name} isRequired>
                      <FormLabel>Your Name</FormLabel>
                      <InputGroup borderColor="#E0E1E7">
                        <InputLeftElement
                          pointerEvents="none"
                          children={<BsPerson color="gray.800" />}
                        />
                        <Input
                          type="text"
                          size="md"
                          placeholder="Full name"
                          {...register('name', {
                            required: 'Your name',
                          })}
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {errors?.name && errors?.name?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.mail} isRequired>
                      <FormLabel>Mail</FormLabel>
                      <InputGroup borderColor="#E0E1E7">
                        <InputLeftElement
                          pointerEvents="none"
                          children={<MdOutlineEmail color="gray.800" />}
                        />
                        <Input
                          type="email"
                          size="md"
                          placeholder="Your email"
                          {...register('mail', {
                            required: 'Your mail',
                          })}
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {errors?.mail && errors?.mail?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.description} isRequired>
                      <FormLabel>Message</FormLabel>
                      <Textarea
                        w={'100%'}
                        borderColor="gray.300"
                        _hover={{
                          borderRadius: 'gray.300',
                        }}
                        placeholder="Message"
                        {...register('description', {
                          required: 'Message',
                        })}
                      />
                      <FormErrorMessage>
                        {errors?.description && errors?.description?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl float="right">
                      <Button
                        type="submit"
                        variant="solid"
                        colorScheme="blue"
                        color="white"
                        _hover={{}}>
                        Send Message
                      </Button>
                    </FormControl>
                  </VStack>
                </form>
              </Box>
            </Box>
          </Box>
        </Center>
      </Stack>
    </Container>
  );
};

export default LetsTalk;
